@font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.startConatiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
}

.startContentCont {
  width: 1700px;
  display: flex;
  flex-direction: column;
}

.startTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.startTop h1 {
  color: var(--Blue, #007cd7);
  font-family: ITC;
  font-size: 35.156px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 125.156% */
  letter-spacing: -0.5px;
  text-align: justify;
  margin: 0;
}

.startTop P {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 15.375px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 156.098% */
  letter-spacing: -0.1px;
  margin: 0;
}

.startBottomCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
}

.startBottom {
  display: flex;
  padding: 10px 30px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 7px solid rgba(255, 255, 255, 0.95);
  background: rgba(255, 255, 255, 0.648);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  gap: 30px;
}

.startBottom h1 {
  max-width: 348.2px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 22.688px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.28px;
}

.startBottom p {
  max-width: 348.2px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 15.375px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .startContentCont {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .startContentCont {
    width: 1200px;
  }
  .startBottomCont {
    gap: 30px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .startContentCont {
    width: 950px;
  }
  .startBottomCont {
    gap: 20px;
  }
  .startBottom {
    display: flex;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .startContentCont {
    width: 90%;
  }
  .startBottomCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0px;
    gap: 20px;
  }
  .startBottom {
    width: 100%;
  }
  .startBottom h1 {
    max-width: 562.56px;
  }

  .startBottom p {
    max-width: 562.56px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .startContentCont {
    width: 90%;
  }
  .startTop h1 {
    max-width: 400 px;
    color: var(--Blue, #007cd7);
    font-family: ITC;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.5px;
    text-align: justify;
    margin: 0;
  }

  .startTop P {
    max-width: 400px;
    color: #4c4c4c;
    font-family: ITC;
    font-size: 15.375px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    margin: 0;
  }
  .startBottomCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0px;
    gap: 20px;
  }
  .startBottom {
    width: 100%;
    padding: 10px 20px;
    border: 4px solid rgba(255, 255, 255, 0.95);
    gap: 0px;
  }
  .startBottom h1 {
    max-width: 400px;
  }

  .startBottom p {
    max-width: 400px;
  }
}

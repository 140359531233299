/* .AppsPowered {
  width: 1700px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.AppsPowered > h1 {
  font-weight: 500;
  font-size: 55px;
  line-height: 75px;
  height: fit-content;
  color: #2b2b2b;
  font-family: "DM Sans";
}

.appswipermain {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.appswiperheader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mySwiper2landingpage {
  width: 100%;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .AppsPowered {
    width: 1420px;
  }

  .mySwiper2landingpage {
    width: 1420px;
  }
  .AppsPowered > h1 {
    font-weight: 500;
    font-size: 50px;
    line-height: 75px;
    height: fit-content;
    color: #2b2b2b;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .AppsPowered {
    width: 1200px;
  }

  .mySwiper2landingpage {
    width: 90%;
  }
  .AppsPowered > h1 {
    font-weight: 500;
    font-size: 50px;
    line-height: 75px;
    height: fit-content;
    color: #2b2b2b;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .AppsPowered {
    width: 950px;
  }

  .mySwiper2landingpage {
    width: 90%;
  }
  .AppsPowered > h1 {
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    height: fit-content;
    color: #2b2b2b;
  }
}
@media screen and (max-width: 980px) {
  .AppsPowered {
    width: 90%;
  }
  .mySwiper2landingpage {
    width: 90%;
  }
  .mySwiper2landingpage > .swiper-slide {
    width: 100%;
  }
  .AppsPowered > h1 {
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    height: fit-content;
    color: #2b2b2b;
  }
}
@media screen and (max-width: 600px) {
  .AppsPowered {
    width: 90%;
  }
  .mySwiper2landingpage {
    width: 90%;
  }
  .mySwiper2landingpage > .swiper-slide {
    margin-left: 0px;
  }
  .AppsPowered > h1 {
    font-size: 28px;
  }
  .swipercs {
    height: fit-content !important;
  }
}

@media screen and (max-width: 350px) {
  .AppsPowered > h1 {
    font-size: 20px;
  }
} */

.AppsPowered {
  width: 1700px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.AppsPowered > h1 {
  font-weight: 700;
  font-size: 55px;
  line-height: 75px;
  height: fit-content;
  color: #2b2b2b;
  font-family: "DM Sans";
}

.swiperSection {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: red; */
  margin-bottom: 20px;
}

/* .swiper-wrapper {
    transition-timing-function: linear;
  } */

.appswipermain {
  /* margin-top: 24px; */
  width: 1200px;
  height: fit-content;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px !important;
}
.appswiperheader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mySwiper2landingpage {
  width: auto;
  margin: 0;
  /* display: flex;
      align-items: flex-start; */
}

.swiper-coverflow {
  display: flex !important;
  align-items: flex-end !important;
  /* align-items: flex-start !important; */
  /* justify-content: flex-end !important; */
  /* background-color: red !important; */
}
.swiper-coverflow .swiper-wrapper {
  display: flex;
  align-items: flex-start !important;
}
.swiper-coverflow .swiper-wrapper .swiper-slide {
  align-self: flex-start !important;
  align-items: flex-start !important;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .AppsPowered {
    width: 1420px;
  }

  .mySwiper2landingpage {
    width: 1420px;
  }
  .AppsPowered > h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    height: fit-content;
    color: #2b2b2b;
  }
  .appswipermain {
    width: 1420px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .AppsPowered {
    width: 1200px;
  }

  .mySwiper2landingpage {
    width: 90%;
  }
  .AppsPowered > h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    height: fit-content;
    color: #2b2b2b;
  }
  .appswipermain {
    width: 1200px;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .AppsPowered {
    width: 950px;
  }

  .mySwiper2landingpage {
    width: 90%;
  }
  .AppsPowered > h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 70px;
    height: fit-content;
    color: #2b2b2b;
  }
  .appswipermain {
    width: 950px;
  }
}
@media screen and (max-width: 980px) {
  .AppsPowered {
    width: 90%;
  }
  .mySwiper2landingpage {
    width: 90%;
  }
  .mySwiper2landingpage > .swiper-slide {
    width: 100%;
    /* margin-left: 5%;
          margin-left: 5%; */
  }
  .AppsPowered > h1 {
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    height: fit-content;
    color: #2b2b2b;
  }
  .appswipermain {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .AppsPowered {
    width: 90%;
  }
  .mySwiper2landingpage {
    width: 90%;
  }
  .mySwiper2landingpage > .swiper-slide {
    margin-left: 0px;
  }
  .AppsPowered > h1 {
    /* display: none; */
    font-size: 28px;
  }
  .swipercs {
    /* width: 350px !important;
          margin-left: 10px !important;
          margin-right: 10px !important; */
    height: fit-content !important;
  }
  .appswipermain {
    width: 90%;
  }
}

@media screen and (max-width: 350px) {
  .AppsPowered > h1 {
    font-size: 20px;
  }
}

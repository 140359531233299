@font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 1700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 36px;
}

.topCont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-left: 0px;
}

#head {
  width: max-content;
  color: #474747;
  font-family: "ITC";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.66px;
  text-align: left;
}

.mainCont {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 24px;
  column-gap: 24px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dataCont {
  width: max-content;
  height: max-content;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: var(--Color, #fff);
  box-shadow: 0px 1.223px 6.116px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

#chartLabel {
  color: #4c4c4c;
  text-align: center;
  font-family: ITC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }
  .topCont{
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
  .topCont{
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  .mainCont{
    column-gap: 12px;
  }
  .topCont{
    margin-left: 25px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
  #head {
    text-align: center;
  }
  .topCont{
    width: 100%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  .topCont{
    margin-left: 0;
    width: fit-content;
  }
  #head{
    font-size: 18px;
    width: inherit;
  }
}

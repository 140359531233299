.psContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(
    108.56deg,
    #bfeeff 0%,
    #ffe6f7 56.93%,
    #ffe6e6 100%
  ); */
  /* box-shadow: 0px -7px 50px rgba(0, 0, 0, 0.2); */
  /* padding-bottom: 78px; */
  /* border: 1px solid black; */
}

.psContentContainer {
  width: 1700px;
  /* min-height: 700px; */
  max-height: fit-content;
  /* border: 1px solid black; */
  display: flex;
}

.psContentContainer > .leftcont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.bgimgPS {
  width: inherit;
  height: inherit;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 156px;
  /* background: radial-gradient(
    250px 200px at 15% 70%,
    rgba(173, 0, 162, 0.1) 0%,
    rgba(217, 217, 217, 0) 100%
  ); */
}

.leftcont > img {
  width: 90%;
  height: 500px;
  border-radius: 20px;
}
.psContentContainer {
  /* background-color: blue; */
  justify-content: center;
}

.psContentContainer > .rightcont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  /* background-color: red; */
  /* border: 1px solid black; */
  padding-right: 9%;
  padding-top: 10px;
}

.psContentContainer > .rightcont > h1 {
  margin: 0;
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 500;
  line-height: 51px;
  padding-bottom: 20px;
}

.psContentContainer > .rightcont > p {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  margin-bottom: 20px !important;
}

.psContentContainer > .rightcont > button {
  width: 253px;
  height: 63px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #007CD7;
  color: #007CD7;
  /* font-family: "DM Sans", sans-serif; */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  background-color: white;
}

@media only screen and (max-width: 1800px) and (min-width: 1441px) {
  .psContentContainer {
    width: 1420px;
  }
  .bgimgPS {
    width: inherit;
    height: inherit;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 156px;
    /* background: radial-gradient(
      250px 190px at 15% 70%,
      rgba(173, 0, 162, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .leftcont > img {
    width: 650px;
    height: 450px;
  }

  .psContentContainer > .rightcont > h1 {
    padding-bottom: 12px;
  }

  .psContentContainer > .rightcont > p {
    margin-bottom: 12px;
  }

  .psContentContainer > .rightcont > button {
    margin-top: 20px;
  }

  .psContentContainer > .rightcont {
    gap: 0px;
    padding-right: 3%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  .psContentContainer {
    width: 1200px;
  }
  .bgimgPS {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 156px;
    /* background: radial-gradient(
      150px 150px at 15% 60%,
      rgba(173, 0, 162, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .leftcont > img {
    width: 550px;
    height: 420px;
  }
  .psContentContainer > .rightcont > button {
    margin-top: 20px;
  }
  .psContentContainer > .rightcont {
    gap: 0px;
    padding-right: 5%;
  }
  .psContentContainer > .rightcont > h1 {
    font-size: 36px !important;
    line-height: 40px !important;
    padding-bottom: 20px;
  }
  .psContentContainer > .rightcont > p {
    font-size: 18px;
    margin: 0 !important;
    line-height: 24px;
    margin-bottom: 20px !important;
  }
  .psContentContainer > .rightcont > button {
    width: 220px;
    height: 50px;
    font-size: 16px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .psContentContainer {
    width: 950px;
  }
  .bgimgPS {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 156px;
    /* background: radial-gradient(
      100px 100px at 15% 60%,
      rgba(173, 0, 162, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .leftcont > img {
    width: 420px;
    height: 360px;
  }

  .psContentContainer > .rightcont {
    padding-right: 5% !important;
  }

  .psContentContainer > .rightcont > button {
    margin-top: 60px;
  }
  .rightcont > h1 {
    font-size: 24px !important;
    line-height: normal !important;
    margin: 0;
  }
  .psContentContainer > .rightcont > p {
    font-size: 13px;
    margin: 0;
    line-height: 20px;
  }
  .psContentContainer > .rightcont > button {
    width: 200px;
    height: 50px;
    font-size: 13px;
    margin-top: 25px;
  }
}

@media only screen and (max-width: 980px) {
  .psContentContainer {
    width: 90%;
    min-height: fit-content;
  }
  .bgimgPS {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 156px;
    /* background: radial-gradient(
      100px 100px at 10% 60%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .leftcont > img {
    width: 340px;
    height: 280px;
    border-radius: 8px;
  }

  .psContentContainer > .rightcont > h1 {
    font-size: 22px !important;
    line-height: 26px !important;
    margin: 0;
    padding-bottom: 10px;
  }
  .psContentContainer > .rightcont > p {
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px !important;
  }
  .psContentContainer > .rightcont > button {
    width: 165px;
    height: 35px;
    font-size: 13px;
    margin-top: 10px;
    border-radius: 6px;
  }
  .psContentContainer > .rightcont {
    height: 268px;
    padding-right: 1% !important;
    margin-left: 10px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .leftcont > img {
    width: 90%;
    height: 250px;
    border-radius: 8px;
  }
  .rightcont {
    padding-right: 2% !important;
  }

  .psContentContainer > .rightcont > button {
    margin-top: 0px;
  }
  .rightcont > h1 {
    font-size: 22px !important;
    line-height: 26px !important;
    margin: 0;
  }
  .psContentContainer > .rightcont > p {
    font-size: 11px;
    line-height: 16px;
    margin: 0;
  }
  .psContentContainer > .rightcont > button {
    width: 165px;
    height: 35px;
    font-size: 13px;
    margin-top: 10px;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 600px) {
  .psContentContainer {
    flex-direction: column;
  }
  .psContentContainer > .leftcont {
    width: 100%;
    margin-bottom: 20px;
    /* border: 1px solid black; */
  }
  .psContentContainer > .leftcont > img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  .psContentContainer > .rightcont {
    width: 100%;
  }
  .bgimgPS {
    padding-bottom: 50px;
    background: none;
  }
  .psContentContainer > .rightcont > h1 {
    text-align: left;
  }
}

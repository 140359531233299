.wrapper {
  padding: 30px 0px;
  width: 100%;
  min-height: 340px;
  position: relative;
  /* background-color: skyblue; */
}

.head {
  color: #4c4c4c;
  text-align: center;
  font-family: ITC;
  font-size: 40.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  text-align: center;

  width: 100%;
  /* margin-bottom: 2%; */
}

.container {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.contentContainer {
  /* border: 2px solid red; */

  width: 75%;
  /* height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.description > p {
  width: 100%;
  color: #4c4c4c;
  text-align: center !important;
  font-family: ITC !important;
  font-size: 17.469px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.73px;
  text-align: center;
  margin: 20px 0;
}

.mainBottomContent {
  /* border: 2px solid green; */
  width: 80% !important;
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3% 0;
}

.bottomContent {
  display: flex;
  align-items: center;
  height: fit-content;
  padding-bottom: 40px;
}

.logo {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 100%;
  border-radius: 50%;
}

.name_designation {
  /* border: 2px solid green; */
  padding-left: 5%;
  width: 100%;
}

.name_designation h1 {
  color: #4c4c4c;
  font-family: ITC !important;
  font-size: 15.469px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.7px;
}

.name_designation p {
  color: #8f96a1;
  font-family: ITC !important;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
}

.swiper-pagination {
  border: 2px solid black;
  background-color: red;
  display: block;
  width: 100px;
}
.pagination-dotsemployee {
  border: 2px solid black;
  background-color: red;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dotemployee {
  width: 12px;
  height: 12px;
  background-color: #d9d9d9;
  opacity: 0.8;
  border-radius: 50%;
  margin: 0 5px;
}

.dotemployee.active {
  background-color: #992e95;
  opacity: 1;
}

.pagination-dotsemployee {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

/* ------------tablet view-------------- */
@media screen and (max-width: 900px) and (min-width: 601px) {
  .head {
    color: #4c4c4c;
    text-align: center;
    font-family: ITC !important;
    font-size: 40.875px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px;
    text-align: center;

    width: 100%;
    margin-bottom: 2%;
  }

  .description > p {
    width: 100%;
    color: #4c4c4c;
    text-align: center;
    font-family: ITC !important;
    font-size: 17.637px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24.73px;
    text-align: center;
  }

  .name_designation h1 {
    color: #4c4c4c;
    font-family: ITC !important;
    font-size: 15.469px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20.7px;
  }

  .name_designation p {
    color: #8f96a1;
    font-family: ITC !important;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
  }
  .swiper-pagination {
    display: block;
  }
  .pagination-dotsemployee {
    border: 2px solid black;
    background-color: red;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

/* ------------tablet view-------------- */

@media screen and (max-width: 600px) and (min-width: 100px) {
  .container {
    height: 370px;
  }

  .contentContainer {
    width: 85%;
  }

  .head {
    color: #4c4c4c;
    text-align: center;
    font-family: ITC !important;
    font-size: 27.375px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px;

    width: 100%;
    margin-bottom: 2%;
  }

  .description > p {
    width: 100%;
    color: #4c4c4c;
    text-align: center;
    font-family: ITC !important;
    font-size: 17.637px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24.73px;
    text-align: center;
  }

  .mainBottomContent {
    width: 100% !important;
    margin-bottom: 10%;
  }

  .logo {
    height: 70px;
    width: 70px;
  }

  .name_designation h1 {
    color: #4c4c4c;
    font-family: ITC !important;
    font-size: 13.75px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 18.4px;

    margin-bottom: 0;
    text-align: justify;
  }

  .name_designation p {
    color: #8f96a1;
    font-family: ITC !important;
    font-size: 13.125px !important ;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
}

@media screen and (max-width: 620px) and (min-width: 552px) {
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 552px) and (min-width: 480px) {
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 350px) {
  .container {
    height: 450px;
  }

.mainBottomContent {
  width: 100% !important;
  margin-bottom: 15%;
  }
}
@media screen and (max-width: 320px) {
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

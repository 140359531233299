.topcont1 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  /* padding-top: 15vh; */
}
@font-face {

  font-family: 'ITC';
  font-style: normal;
  font-weight: normal;
  src:  url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");

}

* {
  font-family: "ITC" !important;
}

.MapMain {
  height: 800px;
}

.maindivcontactspage {
  /* background: url("../../../public/aidtaasImages/images/images/contact/background.png"); */

  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(255, 255, 255, 0.809);  */
}
#contacthead {
  width: 1700px;
  height: fit-content;
  padding-bottom: 10px;
  padding-left: 8%;
  /* border: 2px solid red; */
}
#contacthead > h1 {
  width: 70%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 68px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* letter-spacing: -2.04px; */
  height: fit-content;
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
}
#contacthead > p {
  width: 50%;
  height: fit-content;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
#contacthead > h2 {
  width: fit-content;
  padding: 10px 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  background-color: #ffa200;
  border-radius: 10px;
  margin-top: 3%;
}

.btn_touch{
  width: fit-content;
  padding: 10px 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  background-color: #ffa200;
  border-radius: 10px;
  margin-top: 3%;

}

.botcont {
  width: 100%;
  height: fit-content;
}
.botconthead {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 30px;
}
.botconthead > div {
  width: 1700px;
}
.botconthead > div > h1 {
  width: 60%;
  height: fit-content;
  padding-left: 8%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.17px;
}
#mapcont {
  width: 100%;
  height: 800px;
  background-color: #ffffff;
  position: relative;
}
/* changed width, margin-right */
.mapcontlocations {
  /* border: 2px solid green; */
  /* width: 500px; */
  width: 35vw;
  position: absolute;
  /* z-index: 2; */
  left: 60%;
  /* right: 10%; */
  margin-right: 11.42vw;
  top: -10%;
  height: 750px;
  border-radius: 30px;
  border: 1px solid #d3d3d3;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(50px);
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(100px);
  padding: 2%;
}
.maplocationshead {
  /* border: 2px solid blue; */
  color: #4c4c4c;
  font-family: ITC;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.mapcontent1 {
  height: 30%;
  position: relative;
  display: flex;
  column-gap: 20px;
  margin-top: 3%;
}
.mapcontent1section1 {
  width: 10%;
}

.mapcontent1section1 Img {
  width: 3rem;
  height: 3rem;
}

.mapcontent1section2 {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  height: fit-content;
}
.section2bottom {
  /* border: 2px solid red; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  width: fit-content;
  white-space: nowrap;
}

.section2head {
  font-weight: 500;
  line-height: 25px;
  font-size: 20px;
  color: #1a1a1a;
  font-family: ITC;
}
.section2desc {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section2bottom > div:last-child {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #404040;
}

.horizontalLineMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontalLine {
  background: #b6b6b6;
  height: 1px;
  width: 90%;
  text-align: center;
}

@media only screen and (max-width: 2560px) and (min-width: 1800px) {
  #contacthead {
    padding-left: 0%;
  }
  .botconthead > div > h1 {
    padding-left: 0% !important;
  }

  .mapcontent1 {
    height: 30%;
    position: relative;
    display: flex;
    column-gap: 20px;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1501px) {
  #contacthead {
    padding-left: 0%;
    width: 1420px;
  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }
}


@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  #contacthead {
    padding-left: 0%;
    width: 1200px;
  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }

  .topcont1 {
    padding-top: 60px;
  }
  .mapcontent1section1 Img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .mapcontlocations {
    height: 655px;
  }

  .mapcontent1 {
    height: 27%;
    margin-top: 4%;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1441px) {
  #contacthead {
    padding-left: 0%;
    width: 95% !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  #contacthead {
    padding-left: 0%;
    width: 950px;
  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }

  .topcont1 {
    padding-top: 60px;
  }
  #contacthead > h1 {
    font-size: 50px;
    white-space: nowrap;
  }

  .section2bottom > div:last-child {
    font-size: 13px;
  }

  .logo {
    width: 11%;
    height: 11%;
  }

  .mapcontent1section1 Img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .mapcontent1 {
    height: 28%;
    margin-top: 8%;
  }
}

@media screen and (max-width: 980px) and (min-width: 620px) {
  #contacthead {
    padding-left: 0%;
    width: 90%;
  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }
  .topcont1 {
    padding-top: 50px;
  }
  #contacthead > h1 {
    font-size: 40px;
    white-space: nowrap;
  }

  #contacthead > p {
    font-size: 18px;
    line-height: 28px;
  }

  .botconthead > div > h1 {
    display: none;
  }

  .mapcontent1section1 {
    width: 8%;
  }
  .mapcontent1section1 Img {
    width: 2.5rem;
    height: 2rem;
  }

  .envelope {
    width: 20px !important;
    height: 20px !important;
  }

  .section2bottom > div:last-child {
    font-size: 10px;
  }
  .mapcontlocations {
    height: 700px;
  }

  .mapcontent1 {
    height: 28%;
    margin-top: 8%;
  }
}

@media screen and (max-width: 800px) and (min-width: 620px) {

  #contacthead {
    padding-left: 0%;
    width: 90%;
  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }
  #contacthead > p {
    width: 60%;
  }
  #mapcont {
    /* border: 3px solid red !important; */
    width: 100%;
    /* height: 600px !important; */
    height: fit-content;
    position: relative;
  }

  .MapMain {
    height: 600px;
  }

  .mapcontlocations {
    display: none;
  }

  .contacthead {
    /* padding-bottom: 10px; */
    padding-bottom: 1.5rem;
  }
}

@media screen and (max-width: 620px) and (min-width: 480px) {
  #contacthead {
    padding-left: 0%;
    width: 90%;
    padding-bottom: 20px;

  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }

  .topcont1 {
    padding-top: 30px;
  }
  #mapcont {
    /* border: 3px solid red !important; */
    width: 100%;
    /* height: 600px !important; */
    height: fit-content;
    position: relative;
  }

  .MapMain {
    height: 500px;
  }

  .contacthead {
    padding-bottom: 20px;
    /* padding-top: 1rem !important; */
  }

  #contacthead > h1 {
    font-size: 1.6rem;
    white-space: nowrap;
    line-height: 35px;
  }

  #contacthead > p {
    font-size: 1rem;
    width: 95%;
    line-height: 25px;
  }

  .botconthead > div > h1 {
    display: none;
  }

  .mapcontlocations {
    display: none;
    width: 43vw;

    position: absolute;
    left: 50%;
    padding: 0;
    margin: 0;
    font-size: 10px;
    margin-top: 3vh;
  }

  .maplocationshead {
    font-size: 18px;
    padding-left: 1.5vw;
    padding-top: 2vw;
  }

  .mapcontent1section1 {
    width: 9%;
    padding-top: 1.5vw;
  }

  .mapcontent1 {
    height: 30%;
    column-gap: 10px;
  }

  .section2head {
    font-size: 14px;
    padding-right: 5px;
  }

  .section2desc {
    font-size: 10px;
  }

  .section2bottom > div:last-child {
    font-size: 6px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 480px) {

  #contacthead {
    padding-left: 0%;
    width: 90%;
    padding-bottom: 20px;

  }
  .botconthead > div > h1 {
    padding-left: 0%;
  }
  
  .topcont1 {
    padding-top: 20px;
  }
  #mapcont {
    /* border: 3px solid red !important; */
    width: 100%;
    /* height: 600px !important; */
    height: fit-content;
    position: relative;
  }

  .MapMain {
    height: 500px;
  }

  #contacthead > h1 {
    font-size: 22px;
    white-space: nowrap;
    line-height: 25px;
  }

  #contacthead > p {
    font-size: 12px;
    width: 95%;
    line-height: 20px;
  }

  .botconthead > div > h1 {
    display: none;
  }

  .mapcontlocations {
    display: none;

    width: 43vw;

    position: absolute;
    left: 50%;
    padding: 0;
    margin: 0;
    font-size: 10px;
    margin-top: 3vh;
  }

  .maplocationshead {
    font-size: 14px;
    padding-left: 1.5vw;
    padding-top: 2vw;
  }

  .mapcontent1section1 {
    width: 9%;
    padding-top: 1.5vw;
  }

  .mapcontent1 {
    height: 30%;
    column-gap: 10px;
  }

  .section2head {
    font-size: 12px;
    padding-right: 5px;
  }

  .section2desc {
    font-size: 10px;
    line-height: 16px;
  }

  .section2bottom > div:last-child {
    font-size: 8px;
    padding-left: 0px;
    line-height: 16px;
  }

  #contacthead > h2 {
    margin-top: 6%;
  }
}

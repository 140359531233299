.platformContainer{
    position: relative;
    overflow-x: hidden;
    height: auto;
    width: 100vw;
    /* background-color: aqua; */
}

@font-face {

    font-family: 'ITC';
    font-style: normal;
    font-weight: normal;
    src:  url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
      format("truetype");
  
  }


  *{
    font-family: 'ITC' !important;;;
  }
/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.cookies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.cookiesContainer {
  width: 1700px;
}

.cookiesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.cookiesLeftHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cookiesLeftHeader img {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}

.cookiesLeftHeader p {
  color: #8f96a1;
  text-align: center;
  font-family: ITC;
  font-size: 15.328px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.7px;
}

.cookiesRightHeader {
  width: 258px;
  height: 20px;
  display: flex;
  padding: 12px 11px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--swissborg-com-manatee-50, rgba(143, 150, 161, 0.5));
}

.cookiesRightHeader p {
  color: var(--swissborg-com-black, #000);
  text-align: center;
  font-family: ITC;
  font-size: 13.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px; /* 67.669% */
}

.cookiesRightHeader img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.cookiesFooterContainer {
  display: flex;
  justify-content: space-between;
}

.cookiesFooter {
  width: 250px;
}

.cookiesFooter h6 {
  color: #7e8185;
  font-family: ITC;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.7px;
  margin-bottom: 5%;
}

.cookiesFooterList {
  color: #8f96a1;
  font-family: ITC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  position: relative;
  cursor: pointer;
}
.cookiesFooterList p:hover {
  color: #646567;
}

.cookiesFooterList p span {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40%;
  top: 25%;
  border-radius: 2px;
  background: var(--Blue, #007cd7);
  color: var(--swissborg-com-nero, var(--Color, #fff));
  text-align: center;
  font-family: ITC;
  font-size: 12.25px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.1px;
}

.mobileCookiesContainer {
  display: none !important;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .cookiesContainer {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .cookiesContainer {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .cookiesContainer {
    width: 950px;
  }
  .cookiesFooterList p span {
    position: absolute;
    right: 25%;
    top: 24%;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .cookies {
    flex-wrap: wrap;
  }
  .cookiesContainer {
    width: 90%;
  }

  .cookiesRightHeader {
    width: 143px;
    height: 20px;
  }

  .cookiesFooterContainer {
    display: flex;
    justify-content: flex-start;
    gap: 10%;
  }
  .cookiesFooter {
    width: 26%;
    margin-top: 20px;
  }
  .cookiesFooterList {
    position: relative;
  }
  .cookiesFooterContainer {
    flex-wrap: wrap;
  }
  .cookiesFooterList p span {
    position: absolute;
    right: 20%;
    top: 26%;
  }
}

@media screen and (max-width: 980px) and (min-width: 900px) {
  .cookiesFooterList p span {
    position: absolute;
    right: 30%;
    top: 26%;
  }
}
@media screen and (max-width: 899px) and (min-width: 800px) {
  .cookiesFooterList p span {
    position: absolute;
    right: 22%;
    top: 26%;
  }
}
@media screen and (max-width: 799px) and (min-width: 700px) {
  .cookiesFooterList p span {
    position: absolute;
    right: 16%;
    top: 26%;
  }
  /* .cookiesFooter {
    width: 30%;
  } */
}
@media screen and (max-width: 699px) and (min-width: 600px) {
  .cookiesFooterList p span {
    position: absolute;
    right: 4%;
    top: 26%;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .cookiesContainer {
    display: none;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .cookies {
    margin-top: 100px;
  }
  .mobileCookiesContainer {
    width: 90%;
    display: flex !important;
    flex-direction: column !important;
  }
  .mobileCookiesHeader {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    gap: 20px;
  }
  .mobileCookiesLeftHeader {
    display: flex;
    align-items: center;
    gap: 3%;
  }
  .mobileCookiesRightHeader {
    display: flex;
    align-items: center;
  }
  .mobileCookiesLeftHeader img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    flex-shrink: 0;
  }
  .mobileCookiesLeftHeader p {
    color: #8f96a1;
    text-align: center;
    font-family: ITC;
    font-size: 13.625px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.4px;
  }
  .mobileCookiesRightHeader {
    width: 90%;
    /* height: 40px; */
    height: 40%;
    display: flex;
    padding: 12px 11px;

    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--swissborg-com-manatee-50, rgba(143, 150, 161, 0.5));
  }
  .mobileCookiesRightHeader p {
    color: var(--swissborg-com-black, #000);
    text-align: center;
    font-family: ITC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 9px; /* 67.669% */
  }
  .mobileCookiesRightHeader img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .mobileCookiesAccordionCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
  }
  .mobileCookiesAccordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgba(143, 150, 161, 0.5);
    margin-bottom: 8%;
  }

  .mobileCookiesAccordionHeaderCont {
    width: 95%;
    display: flex;
    justify-content: space-between;
  }

  .mobileCookiesAccordionHeader {
    /* max-height: 16.1px; */
    color: #8f96a1;
    font-family: ITC;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16.1px;
    width: 90%;
  }

  .mobileCookiesAccordionImg {
    height: 34px;
    min-height: 34px;
  }
  .mobileCookiesAccordionList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-top: 1px solid #f9f9f9;
    color: #8f96a1;
    font-family: ITC Avant Garde Gothic;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    font-family: "ITC";
    margin-bottom: 5%;
  }
  .mobileCookiesAccordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out;
  }

  .mobileCookiesAccordionContent.active {
    max-height: 220px;
  }
}

.menuContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(
    108.56deg,
    #bfeeff 0%,
    #ffe6f7 56.93%,
    #ffe6e6 100%
  ); */
  /* border: 1px solid black; */
  /* padding-top: 78px; */
}

.menuContentContainer {
  width: 1700px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 83px;
}

.content3 {
  /* border: 1px solid black; */
  width: fit-content;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 90px;
  border-radius: 10px;
  background: #fff;
  padding-left: 65px;
  padding-right: 65px;
  scrollbar-width: 0px !important;
  overflow: "scroll";
}
.content4 {
  width: fit-content;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 90px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  scrollbar-width: 0px !important;
}

.content3 > span > a {
  color: #1a1a1a;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.content3 > span > a {
  position: relative;
  transition: all 0.5s ease;
}

.content3 > span > a:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background: #007CD7;
}

.bg_subheadingmenu {
  height: 250px;
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: radial-gradient(
    350px 200px at 97% 24%,
    rgba(67, 136, 240, 0.1) 0%,
    rgba(217, 217, 217, 0) 100%
  ); */
  background: none;
  /* border: 1px solid black; */
}

@media only screen and (max-width: 1800px) and (min-width: 1440px) {
  .menuContentContainer {
    width: 1420px;
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      200px 140px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
    background: none;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  .menuContentContainer {
    width: 1200px;
    margin-bottom: 50px;
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      200px 120px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
    background: none;
  }
}

@media only screen and (max-width: 1250px) {
  .content3 {
    width: fit-content;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    border-radius: 10px;
    /* background: #fff; */
  }
  .content3 > span > a {
    font-size: 16px;
  }
  .content3 > span > a {
    position: relative;
    transition: all 0.5s ease;
  }

  .content3 > span > a:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    background: #007CD7;
  }
  .menuContentContainer {
    margin-bottom: 50px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .menuContentContainer {
    width: 950px;
  }
}

@media only screen and (max-width: 980px) {
  .menuContentContainer {
    width: 90%;
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      120px 100px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .content3 {
    gap: 35px;
    padding-left: 0;
    padding-right: 0px;
  }
  .content3 > span > a {
    font-size: 13px;
  }
  .content3 > span > a {
    position: relative;
    transition: all 0.5s ease;
  }

  .content3 > span > a:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 3px;
    width: 100%;
    border-radius: 2px;
    background: #007CD7;
  }
  .menuContentContainer {
    margin-bottom: 50px;
    height: 50px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 670px) {
  .menuContentContainer {
    width: 90%;
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      120px 120px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .content3 {
    gap: 20px;
  }
  .content3 > span > a {
    font-size: 11px;
  }
  .content3 > span > a {
    position: relative;
    transition: all 0.5s ease;
  }

  .content3 > span > a:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 3px;
    width: 100%;
    border-radius: 2px;
    background: #007CD7;
  }
  .menuContentContainer {
    margin-bottom: 50px;
    height: 50px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 530px) {
  .menuContentContainer {
    width: 90%;
    /* border: 1px solid black; */
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      120px 120px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .content3 {
    width: 100%;
    gap: 10px;
    position: relative;
    overflow: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .content4 {
    width: 100%;
    gap: 20px;
    position: relative;
    overflow: none;
    height: fit-content;
    border-radius: 10px !important;
  }
  .content4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    background: radial-gradient(
      circle,
      transparent 30%,
      rgb(255, 255, 255) 100%
    );
    pointer-events: none;
    border-radius: 5px !important;
  }

  .content3::-webkit-scrollbar {
    display: none;
  }
  .content3 > span > a {
    font-size: 10px;
  }
  .content3 > span > a {
    position: relative;
    transition: all 0.5s ease;
  }

  .content3 > span > a:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background: #007CD7;
  }
  .menuContentContainer {
    margin-bottom: 50px;
    height: 40px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .menuContentContainer {
    width: 90%;
    /* border: 1px solid black; */
  }
  .bg_subheadingmenu {
    height: fit-content;
    /* background: radial-gradient(
      120px 120px at 97% 24%,
      rgba(67, 136, 240, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .content3 {
    gap: 20px;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-x: scroll;
    overflow-y: hidden;
    border-radius: 6px;
    /* background: linear-gradient(
      90deg,
      #ffffff 1.9%,
      rgba(255, 255, 255, 0) 49.07%,
      #ffffff 97.3%
    ); */
  }

  #firstele {
    margin-left: 470px;
    /* transform: translateX(-50%); */
  }
  #lastele {
    margin-right: 170px;
  }

  .content3 > span > a {
    font-size: 10px;
    height: 13px;
    width: max-content;
    display: inline-block;
    color: #1a1a1a;
  }
  .content3 > span > a {
    position: relative;
    transition: all 0.5s ease;
  }

  .content3 > span > a:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background: #007CD7;
  }
  .menuContentContainer {
    margin-bottom: 50px;
    height: 40px;
    margin-top: 10px;
  }
}

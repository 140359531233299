/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.mobileContainer {
  display: flex;
  /* padding: 60px 0px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mobileContentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 5%; */
  border-radius: 10px;
  background: #f9fafa;
}

.mobileContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 600px;
}

.mobileLeftHeader p {
  max-width: 400px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 30.797px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.4px; /* 134.429% */
  margin: 0;
}

.mobileLeftDesc {
  padding-right: 150px;
}

.mobileLeftDesc p {
  max-width: 500px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* text-indent: 20px; */
  margin: 0;
}
.mobileLeftImage {
  gap: 20px;
  /* gap: 5%; */
  display: flex;
  align-items: start;
  /* justify-content: space-between; */
}

.mobileLeftImage img {
  /* max-width: 150px; */
}
.mobileContentRight img {
  width: 280px;
  height: 607px;
  border-radius: 36px;
  background: rgba(255, 255, 255, 0);
  box-shadow: inset -30px -30px 5px 40px rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .mobileContentContainer {
    width: 1420px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  /* .mobileContainer {
    padding: 40px 0;
  } */
  .mobileContentContainer {
    width: 1200px;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 980px) {
  /* .mobileContainer {
    padding: 40px;
  } */
  .mobileContentContainer {
    width: 950px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  /* .mobileContainer {
    padding: 40px;
  } */
  .mobileContentContainer {
    width: 90%;
  }
  .mobileContentLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 400px;
  }
  .mobileLeftHeader p {
    max-width: 300px;
  }

  .mobileLeftDesc {
    padding-right: 40px;
  }

  .mobileLeftDesc p {
    max-width: 500px;
  }
  .mobileLeftImage {
    gap: 5px;
  }

  .mobileContentRight img {
    width: 280px;
    height: 607px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0);
    box-shadow: inset -30px -30px 5px 40px rgba(0, 0, 0, 0.9);
  }
}

@media screen and (max-width: 750px) and (min-width: 601px) {
  .mobileLeftImage {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .mobileContentContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 5%; */
    padding: 40px 0;
  }
  .mobileContentLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 90%;
  }
  .mobileLeftHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .mobileLeftHeader p {
    width: 100%;
    color: #4c4c4c;
    font-family: ITC;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.4px; /* 134.429% */
    margin: 0;
  }
  .mobileLeftDesc {
    padding-right: 0px;
  }

  .mobileLeftDesc p {
    width: 100%;
    /* line-height: 40px; */
    text-align: justify;
  }
  .mobileLeftImage {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobileContentRight {
    margin-top: 60px;
  }
}

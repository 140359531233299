.modalContainer {
    position: absolute;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 500px;
    margin: 50px auto; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 10px 0px;
    z-index: 99999999999 !important;
    margin-top: 22%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    /* background-color: red; */

  }

  .modalContainer > h2{
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* width: 100%; */
    text-align: left;
    color:  #4C4C4C;
  }
  label{
    color: #474747;
    font-size: 14px;
  }

  .svgg{
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    float: right !important;
    
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .svgg:hover{
    cursor: pointer;

  }

  .h11{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  /* .texts{
  } */
/* .form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
} */
  .modalFormStyle{
    height: 450px;
    top: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inputField {
    height: 40px;
    width: 100% !important;
    background-color: #E1E1E1;
    opacity: 65%;
    border-radius: 10px;
    color: #000;
    border-radius: 8px;
border: 1px solid #C4C4C4;
background: var(--Color, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  
  .inputField:focus{
    color: black;
  }

.first{
  display: flex;
  gap: 15px;
}

  .submitButton {
    height: 50px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    background-color: #007CD7 !important;
    border-radius: 10px;
    padding: 10px 30px;
    cursor: pointer;
    margin-top: 2%;
    white-space: nowrap;
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }
  
  

  @media screen and (max-width:1700px) {
    .modalContainer{
      top: 10px;
      margin-top: 10%;
      
    }
    
  }

  @media screen and (max-width:1000px) {
    .modalContainer{
      top: 7%;
    }
    
  }
/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.inputContainer {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
}

.inputContentContainer {
  width: 1700px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  gap: 19.998px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Surface-Primary, #d8efff);
}

.inputLeft {
  display: flex;
  align-items: center;
  width: 500px;
  display: flex;
  padding: 13.5px 10px;
  align-items: flex-start;
}

.inputLeftContent {
  display: flex;
  align-items: center;
}

.inputLeftInputData {
  width: 400px;
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1px 117px 2px 0px; */
  padding: 13.5px 10px;
  /* flex-direction: column; */
  align-items: flex-start;
  border: none;
}

input:focus {
  outline: none;
  font-size: 16px;
  color: #8f96a1;
  font-family: ITC;
}

.inputLeftInputData::placeholder {
  color: #8f96a1;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  position: relative;
}

.inputLeftHidepw {
  position: absolute;
  right: 74%;
}

.inputLeftHidepwImg {
  width: 25px;
  height: 25px;
}

.inputLeftBtn {
  height: 68px;
  display: flex;
  padding: 20px 18px;
  align-items: flex-start;
  /* position: absolute;
    right: 0px; */
  border-radius: 0px 5px 5px 0px;
  background: var(--Blue, #007cd7);
  border: none;
}

.inputLeftBtn button {
}

.inputRight {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  gap: 10px;
}

.inputRight p {
  /* max-height: 20.7px; */
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
}

.inputRight img {
  width: 20px;
  /* align-self: stretch; */
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .inputContentContainer {
    width: 1420px;
  }
  .inputLeftHidepw {
    right: 70%;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .inputContentContainer {
    width: 1200px;
  }
  .inputLeftHidepw {
    right: 65%;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .inputContentContainer {
    width: 950px;
  }
  .inputLeftHidepw {
    right: 60%;
  }
  .inputLeft {
    width: 430px;
  }
  .inputLeftInputData {
    width: 350px;
  }
}

@media only screen and (max-width: 980px) and (min-width: 601px) {
  .inputContentContainer {
    width: 90%;
  }
  .inputLeftHidepw {
    right: 60%;
    top: 58px;
  }
  .inputLeft {
    width: 350px;
  }
  .inputLeftInputData::placeholder {
    font-size: 14px;
  }

  .inputRight p {
    font-size: 14px;
  }

  .inputLeftInputData {
    width: 250px;
  }
}

@media only screen and (max-width: 980px) and (min-width: 950px) {
  .inputLeftHidepw {
    right: 67%;
  }
}
@media only screen and (max-width: 949px) and (min-width: 900px) {
  .inputLeftHidepw {
    right: 65%;
  }
}
@media only screen and (max-width: 899px) and (min-width: 850px) {
  .inputLeftHidepw {
    right: 64%;
  }
}
@media only screen and (max-width: 849px) and (min-width: 800px) {
  .inputLeftHidepw {
    right: 62%;
  }
}
@media only screen and (max-width: 799px) and (min-width: 750px) {
  .inputLeftHidepw {
    right: 60%;
  }
}
@media only screen and (max-width: 749px) and (min-width: 700px) {
  .inputLeftHidepw {
    right: 58%;
  }
  .inputLeft {
    width: 340px;
  }
  .inputLeftInputData {
    width: 230px;
  }
  .inputLeftInputData::placeholder {
    font-size: 12px;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 699px) and (min-width: 650px) {
  .inputLeftHidepw {
    right: 55%;
  }
  .inputLeft {
    width: 320px;
  }
  .inputLeftInputData {
    width: 200px;
  }
  .inputLeftInputData::placeholder {
    font-size: 11px;
    padding-left: 5px;
  }
}
@media only screen and (max-width: 649px) and (min-width: 601px) {
  .inputLeftHidepw {
    right: 52%;
  }
  .inputLeft {
    width: 320px;
  }
  .inputLeftInputData {
    width: 180px;
  }
  .inputLeftInputData::placeholder {
    font-size: 10px;
    padding-left: 5px;
  }
}

@media only screen and (max-width: 630px) and (min-width: 601px) {
  .inputLeftHidepw {
    right: 52%;
  }
  .inputLeft {
    width: 320px;
  }
  .inputLeftInputData {
    width: 180px;
  }
  .inputLeftInputData::placeholder {
    font-size: 10px;
    padding-left: 5px;
  }
}

/* mobile view */

@media only screen and (max-width: 601px) and (min-width: 320px) {
  .inputContentContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .inputTopCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .inputLeft {
    display: flex;
    align-items: center;
    width: 320px;
    justify-content: center;
    /* width: 90%; */
    display: flex;
    padding: 13.5px 10px;
    align-items: flex-start;
  }

  .inputLeftContent {
    display: flex;
    align-items: center;
  }

  .inputLeftInputData {
    width: 200px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1px 117px 2px 0px; */
    padding: 13.5px 10px;
    /* flex-direction: column; */
    align-items: flex-start;
    border: none;
  }

  .inputLeftInputData::placeholder {
    font-size: 10px;
  }
  .inputLeftHidepw {
    right: 38%;
  }
  .inputLeftBtn {
    height: 58px;
  }
  .inputLeftHidepwImg {
    width: 20px;
    height: 20px;
  }
  .inputRight {
    padding: 0;
    display: flex;
    align-items: center;

    justify-content: start;
  }
  .inputRight p {
    font-size: 13.25px;
  }
  .inputLeftBtn {
    padding: 16px 18px;
  }
}

@media only screen and (max-width: 601px) and (min-width: 500px) {
  .inputLeftHidepw {
    right: 38%;
    top: 32px;
  }
}
@media only screen and (max-width: 499px) and (min-width: 450px) {
  .inputLeftHidepw {
    right: 36%;
    top: 33px;
  }
}
@media only screen and (max-width: 449px) and (min-width: 400px) {
  .inputLeftHidepw {
    right: 36%;
    top: 33px;
  }
}
@media only screen and (max-width: 399px) and (min-width: 350px) {
  .inputLeftHidepw {
    right: 34%;
    top: 33px;
  }
}
@media only screen and (max-width: 349px) and (min-width: 320px) {
  .inputLeftHidepw {
    right: 32%;
    top: 32px;
  }
}

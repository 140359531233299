
.topcont{
    position:relative; 
    min-height: 32vh;
   max-height: fit-content ;
   padding: 0px 0px 0px 0px;
   width: 100%;
   
}
.botcont{
   position: relative;
min-height: 35vh;
/* background-color: red; */
max-height: fit-content;
width: 100%;
}
.listitems{
   margin-bottom: 40px;
   /* background-color: red; */
}

.listitems1{
   margin-top: 10px;
}
/* .whereToFind {
  margin-left: 5% !important;
}
@media screen and (max-width: 650px) {
  .whereToFind {
    margin-left: 0% !important;
  }
} */

.where {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

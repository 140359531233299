.main {
  width: 550px;
  min-height: 450px;
  background: linear-gradient(
    118deg,
    rgba(64, 175, 255, 0.2) -8.52%,
    rgba(41, 177, 253, 0.2) 27.6%,
    rgba(255, 168, 0, 0.2) 63.71%,
    rgba(251, 180, 42, 0.2) 99.82%
  );
  border-radius: 5px;
}

.internal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 50px 50px;
}

.heading {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 25.922px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheading {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 100%;
}

.inputs {
  /* width: 400px !important; */
  /* overflow-x: hidden; */
  width: 100%;
}

.input1 {
  display: flex;
  width: 100% !important;
  min-height: 20.4px;
  padding: 10px 21px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  opacity: 0.8;
  background: rgba(76, 76, 76, 0.2);
  border: 0px solid beige;
}
* {
  box-sizing: border-box;
}

.input1::placeholder {
  color: #4c4c4c;
  /* color: red; */
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

select {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

option {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.check {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #4c4c4c;
  opacity: 0.8;
  background: rgba(76, 76, 76, 0.1);
}

.checkboxx {
  display: flex;
  align-items: flex-start;

  justify-content: flex-start;
}

.checkdesc {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.button {
  display: flex;
  padding: 6px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Yellow, #ffa200);
  border: 0px solid beige;
  color: white;
  margin-top: 10px;
}

.inputField {
  height: 40px;
  width: 100% !important;
  background-color: #e1e1e1;
  opacity: 65%;
  border-radius: 10px;
  color: #000;
}

.submitButton {
  height: 50px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  background-color: #ffa200 !important;
  border-radius: 10px;
  padding: 10px 30px;
  cursor: pointer;
  margin-top: 2%;
  white-space: nowrap;
}
.submitButton:hover {
  background-color: #45a049;
}

.redBorder {
  border: 1px solid red;
}

.heading {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 25.922px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheading {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

* {
  box-sizing: border-box;
}

select {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

option {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.check {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #4c4c4c;
  opacity: 0.8;
  background: rgba(76, 76, 76, 0.1);
}

.checkboxx {
  display: flex;
  align-items: flex-start;

  justify-content: flex-start;
}

.checkdesc {
  color: #4c4c4c;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.button {
  display: flex;
  padding: 6px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Yellow, #ffa200);
  border: 0px solid beige;
  color: white;
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 16px;
}

@media screen and (max-width: 612px) {
  .main {
    width: 100%;
  }
  .internal {
    padding: 30px 20px;
  }
  .inputs {
    width: 100% !important;
  }
  .button {
    width: 100%;
  }
  h1 {
    margin-bottom: 20px;
  }
  .error {
    color: red;
    font-size: 12px;
  }
}

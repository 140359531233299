@font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {
  width: 100%;
  height: 100vh;
  /* background: #161616; */
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  display: flex;
  width: 1700px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.bgImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 600px;
  background: url(../../../public/Images/Group-modified.png);
  background-repeat: no-repeat;
  background-size: 600px 100%;
  background-position: center;
  z-index: -100px;
}

#head {
  text-align: center;
  font-family: ITC;
  font-size: 140px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(
    --gradeint-100,
    linear-gradient(
      118deg,
      #40afff -8.52%,
      #29b1fd 27.6%,
      #ffa800 63.71%,
      #fbb42a 99.82%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#shead {
  color: #f5f5f5;
  text-align: center;
  font-family: ITC;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
}

#para {
  color: #f5f5f5;
  text-align: center;
  font-family: ITC;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 748px;
}

.bgImg > button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 64px;
  border-radius: 8px;
  background-color: #ffa200;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  #head {
    font-size: 120px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  #head {
    font-size: 70px;
  }
  #shead {
    font-size: 28px;
  }
  #para {
    font-size: 18px;
    width: 80%;
  }
  .bgImg {
    background-size: 550px 100%;
    height: 600px;
    gap: 16px;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  #head {
    font-size: 50px;
  }
  #shead {
    font-size: 20px;
    line-height: normal;
  }
  #para {
    font-size: 12px;
    width: 80%;
  }
  .bgImg {
    background-size: 300px 100%;
    height: 300px;
    gap: 16px;
  }
  .bgImg>button{
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  #head {
    font-size: 40px;
  }
}

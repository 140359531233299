/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2% 0; */
  padding: 2% 0;

  /* border: 1px solid blue; */
}

.notifyContainer {
  width: 1700px;
  text-align: center;
  padding: 0px 132px;
}

.mainText > h1 {
  /* color: #4c4c4c; */
  text-align: center;
  font-family: ITC;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 55px;
}

.main_para > p {
  text-align: center;
  font-family: ITC;
  font-size: 14.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px; 

  margin-bottom: 3%;
}

.secondryText > p {
  /* color: #4c4c4c; */
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;

  margin-bottom: 1%;
}

.notifyBtn {
  border-radius: 4px;
  /* background: var(--Blue, #007cd7);
  color:#fff; */
  text-align: center;
  font-family: ITC;
  font-size: 17.188px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  padding: 8px 20px;

  border: none;
  cursor: pointer;
  margin-bottom: 3%;
}

.imageContainer {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  gap: 10px;
}
/* 
.imageWrapper img{

} */

/* ----for desktop view---------- */
@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .notifyContainer {
    margin: 0 auto;
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .notifyContainer {
    width: 1200px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .notifyContainer {
    margin: 0 auto;
    width: 950px;
  }
}

/* -----for tablet view---------- */
@media only screen and (max-width: 980px) and (min-width: 601px) {
  .notifyContainer {
    margin: 0 auto;
    width: 90%;
    padding: 0px 24px;
  }

  .mainText > h1 {
    /* color: #4c4c4c; */
    text-align: center;
    font-family: ITC;
    font-size: 41.063px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px;
  }
}

@media only screen and (max-width: 601px) and (min-width: 200px) {
  .imageContainer {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 80%;
    margin: 0 auto;
  }

  .imageWrapper img {
    width: 95%;
  }

  .notifyContainer {
    width: 90%;
    text-align: center;
    padding: 0px 24px;
    flex-direction: column;
  }

  .mainText > h1 {
    /* color: #4c4c4c; */
    text-align: center;
    font-family: ITC;
    font-size: 27.125px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px;
  }

  .secondryText > p {
    /* color: #4c4c4c; */
    font-family: ITC;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 10px;
  }

  .notifyBtn {
    border-radius: 4px;
    /* background: var(--Blue, #007cd7); */
    /* color: #fff; */
    text-align: center;
    font-family: ITC;
    font-size: 15.469px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20.7px;
    padding: 8px 24px !important;

    border: none;
    cursor: pointer;
  }
}

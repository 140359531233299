
.secContainerSP1 {

  padding-top: 2%;
  padding-bottom: 2%;
  /* padding: 2%; */
  padding-left: 5%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 10px;
background: linear-gradient(92deg, #E8F3FB 3.51%, #57B8FF 98.73%);
  /* width: 1700px;   */
  /* background-color: red; */
  position: relative;
}

.secContentContainerSP {
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  /* gap: 120px; */
  /* border: 1px solid black; */
  /* background-color: black; */
  width: 700px !important;

}

.secContentContainerSP > .leftdiv {
  width: 700px !important;
  /* background-color: blue; */
  /* border: 1px solid black; */
}

.secContentContainerSP > .leftdiv > h1 {
  color: #1a1a1a;
  /* font-family: "DM Sans", sans-serif; */
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 58px;
}

.secContentContainerSP > .leftdiv > h5 {
  color: #1a1a1a;
  /* font-family: "DM Sans", sans-serif; */
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.secContentContainerSP > .leftdiv > button {
  width: 253px;
  height: 63px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #007CD7;
  color: #007CD7;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  background-color: white;
  /* background-color: red; */
  line-height: normal;
  margin-top: 30px;
}

.secContainerSP1 > .rightdiv {
  width: 400px;
  height: 332px;
  position: absolute;
  bottom: 0;
  /* background-color: red; */
  right: 3%;
}

.secContainerSP1 > .rightdiv > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media only screen and (max-width: 2600px) and (min-width: 1920px) {
  .secContainerSP1{
    padding-left: 18%;
  }
  .secContainerSP1 > .rightdiv {
    right: 15%;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1440px) {
  .secContainerSP1 > .rightdiv > img {
    height: 90%;
    margin-top: 10%;
  }
  .secContainerSP1{
    padding-left: 8%;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  /* .secContentContainerSP {
    width: 1200px;
  } */
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .secContentContainerSP {
    width: 980px;
    /* height: 400px; */
  }
  .secContentContainerSP > .leftdiv > h1 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 58px;
  }
  .secContentContainerSP > .leftdiv > button {
    width: 220px;
    height: 55px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007CD7;
    color: #007CD7;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
  }
  .secContainerSP1 > .rightdiv {
    width: 500px;
    height: 225px;
    right: 0;
  }
  .secContainerSP1 > .rightdiv > img{
    object-fit: contain;
  }

}

@media only screen and (max-width: 980px) {
  .secContainerSP1{
    height: 200px;
  }
  .secContentContainerSP {
    width: 90%;
    gap: 0px;
  }
  .secContentContainerSP > .leftdiv > h1 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  /* .secContentContainerSP > .rightdiv {
    width: 300px;
    height: 200px;
  } */

  .secContainerSP1 > .rightdiv {
    width: 200px;
    height: 180px;
    right: 3%;
  }

  .secContainerSP1 > .rightdiv > img{
    object-fit: cover;
  }


  .secContentContainerSP > .leftdiv > h5 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }
  .secContentContainerSP > .leftdiv > button {
    width: 140px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007CD7;
    color: #007CD7;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 15px;
  }
}


@media only screen and (max-width: 800px) {
  .secContainerSP1{
    height: 170px;
  }
  .secContentContainerSP {
    width: 90%;
    gap: 10px;
  }
  .secContentContainerSP > .leftdiv > h1 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }

  .secContainerSP1 > .rightdiv {
    width: 200px;
    height: 130px;
    bottom: 0;
  }

  .secContainerSP1 > .rightdiv > img{
    object-fit: contain;
  }


  .secContentContainerSP > .leftdiv > h5 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }
  .secContentContainerSP > .leftdiv > button {
    width: 120px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007CD7;
    color: #007CD7;
    font-family: "DM Sans", sans-serif;
    font-size: 9.52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .secContainerSP1 > .secContentContainerSP {
    width: 90%;
    display: block;
    gap: 0px;
    height: max-content;
    /* position: relative; */
    padding-top: 15px;
    position: absolute;
    bottom: 0;
    /* padding-bottom: 22px; */
    /* border: 1px solid black; */
    /* background-color: red; */
  }
  .secContentContainerSP>.leftdiv{
    width: 230px !important;
   
  }
  .secContainerSP1{
    position: relative;
    height: max-content;
    height: 170px;
  }
  .secContainerSP1 > .rightdiv {
    width: 200px;
    height: 120px;
    position: absolute;
  bottom: 0;
  right: 0;
    /* border: 1px solid black; */
    /* top: 2.3rebm;
    */
  }
  .secContainerSP1 > .rightdiv >img{
    position: absolute;
  
  }
  .secContentContainerSP > .leftdiv > h1 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 120%;
    text-align: left;
  }
  .secContentContainerSP > .leftdiv > h5 {
    color: #1a1a1a;
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70% !important;
    /* background-color: red; */
  }
  .secContentContainerSP > .leftdiv > button {
    width: 120px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007CD7;
    color: #007CD7;
    font-family: "DM Sans", sans-serif;
    font-size: 9.52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 10px;
  }
  .secContentContainerSP > .leftdiv > button{
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 480px) {

  .secContainerSP1 > .rightdiv {
    width: 190px;
    height: 120px;
    position: absolute;
  bottom: 0;
  right: 0;
    /* border: 1px solid black; */
    /* top: 2.3rebm;
    */
  }
 
}

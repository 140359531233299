.card {
  height: 486px;
  /* max-height: fit-content; */
  width: 340px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid #cfcfcf;
  background-color: #ffffff;
}
.Imagecontainer {
  width: 100%;
  height: 50%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.content1 > p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Imagecontainer2 {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(
    5.11deg,
    #262226 -11.19%,
    rgba(114, 32, 224, 0) 95.72%
  );
}
.heading {
  font-weight: 500;
  font-size: 29px;
  color: #ffffff;
  margin-bottom: 10px;
  /* background-color: red; */
}
/* .Imagecontainer :hover {
  background: linear-gradient(
    180deg,
    #7220e000 0%,
    #8232edb9 72.4%,
    #ad00a2 100%
  );
} */
.contentcontainer {
  height: 50%;
  position: relative;
  padding: 12px;
}
.content1 {
  min-height: 80%;
  font-weight: 400;
  font-size: 14.7px;
  line-height: 22px;
  color: #121212;
  /* overflow-y: scroll; */
}
.contentbtns {
  display: flex;
}
.contentbtns > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 5%;
}
.contentbtns > div:first-child {
  border-right: 2px solid #cfcfcf;
}
.names {
  font-weight: 500;
  font-size: 20px;
  color: #797979;
}
.prev {
  height: 24px;
  cursor: pointer;
}

@media screen and (min-width: 600px) and (max-width: 980px) {
  .content1 {
    font-size: 8.76px;
    line-height: 13px;
    font-weight: 500;
    min-height: 70%;
    max-height: 70px !important;
    /* line-height: 3px; */
  }

  .content1 > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .heading {
    font-size: 17px;
  }

  .card {
    width: 198px;
    height: 284px;
    border-radius: 10px;
  }
  .names {
    font-size: 7.5px !important;
  }
  .prev {
    height: 12px;
  }
  .images {
    height: 12px;
  }
  .contentbtns {
    margin-top: 5px;
    height: 12px;
  }
  .contentcontainer {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 20px;
  }
  .Imagecontainer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

@media screen and (max-width: 600px) {
  .content1 {
    font-size: 8.76px;
    line-height: 11px;
    font-weight: 500;
    min-height: 75%;
    /* background-color: red; */
    max-height: 70px !important;
  }

  .content1 > p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card {
    height: 360px;
    width: 260px;
    border-radius: 10px;
  }
  .heading {
    font-size: 16px;
    font-weight: 500;
  }
  .content1 {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    margin: 5px;
  }
  .contentcontainer {
    margin: 0;
    padding: 5px;
  }
  .prev {
    height: 7.5px !important;
  }
  .images {
    height: 12px !important;
    width: 12px;
    /* background-color: red; */
  }
  .contentbtns {
    margin-top: 2px;
    height: 10px !important;
    /* background-color: blue; */
    align-items: center;
    justify-content: center;
  }
  .names {
    font-size: 13px;
    line-height: 7.63px;
  }
  .Imagecontainer {
    /* border-radius: 5px;
       */
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&display=swap");

.bannerContainerSolution {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(
    108.56deg,
    #bfeeff 0%,
    #ffe6f7 56.93%,
    #ffe6e6 100%
  ); */
  /* box-shadow: 0px -7px 50px rgba(0, 0, 0, 0.2); */
  /* border-radius: 0px 0px 60px 60px; */
  padding-bottom: 78px;
}

.bannerContentContainerSolution {
  width: 1700px;
  min-height: 700px;
  max-height: fit-content;
  /* border: 1px solid black; */
  display: flex;
  /* padding-top: 150px; */
  align-items: center;
}

.bannerContentContainerSolution > .leftcont {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* align-items: center; */
  justify-content: center;
  /* border: 1px solid black; */
  padding-right: 10%;
  /* color: #007CD7; */
  /* background-color: red; */
}

.bannerContentContainerSolution > .leftcont > h1 {
  color: #ce31c4;
  /* font-family: "DM Sans"; */
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  color: #007cd7;

  /* line-height: 54px; */
  /* letter-spacing: 1.32px; */
  margin-bottom: 19px;
}

.leftcont > p {
  color: #313131;
  /* font-family: "DM Sans"; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 33px; */
  /* letter-spacing: 0.6px; */
  margin-bottom: 119px;
}

.leftcont > button {
  width: 216px;
  height: 53px;
  display: flex;
  padding: 30px 42px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ffa200;
  background: #ffa200;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-family: "DM Sans", sans-serif;
}

.rightcont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* border: 1px solid black; */
}

.rightcont > img {
  width: 702px;
  height: 465px;
  border-radius: 14px;
  object-fit: contain;
  
}
/* changes made */
#bg_headingsolution {
  height: fit-content;
  /* background: radial-gradient(
    300px 250px at 30% 50%,
    rgba(234, 169, 229, 0.4) 10%,
    rgba(217, 217, 217, 0) 90%
  ); */
}

@media only screen and (max-width: 1800px) and (min-width: 1440px) {
  .bannerContentContainerSolution {
    width: 1420px;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      200px 200px at 30% 50%,
      rgba(234, 169, 229, 0.5) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
  }
  .bannerContentContainerSolution > .leftcont {
    padding-right: 10%;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  .bannerContentContainerSolution {
    width: 1200px;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      200px 200px at 30% 50%,
      rgba(234, 169, 229, 0.4) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
  }
  .bannerContentContainerSolution > .leftcont {
    padding-right: 3%;
  }

  .bannerContentContainerSolution > .leftcont > p {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1250px) {
  .bannerContentContainerSolution > .leftcont {
    padding-right: 5%;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      180px 200px at 30% 50%,
      rgba(234, 169, 229, 0.7) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
  }
  .bannerContentContainerSolution > .leftcont > h1 {
    font-size: 32px;
  }
  .leftcont > p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .rightcont > img {
    width: 600px;
    height: 400px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .bannerContentContainerSolution {
    width: 950px;
    min-height: 600px;
  }

  #bg_headingsolution {
    height: fit-content;
  }
}

@media only screen and (max-width: 980px) {
  .bannerContentContainerSolution {
    width: 90%;
    min-height: 500px;
  }
  .bannerContentContainerSolution > .leftcont > h1 {
    font-size: 26px;
    margin: 0;
  }
  .leftcont > p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .leftcont > button {
    width: 142px;
    height: 32px;
    border-radius: 6px;
    font-size: 13px;
    padding: 15px 26px;
  }
  .rightcont > img {
    width: 336px;
    height: 268px;
    border-radius: 8px;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      120px 120px at 30% 50%,
      rgba(234, 169, 229, 0.05) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
    background: none;
  }
}

@media only screen and (max-width: 670px) {
  .bannerContentContainerSolution {
    width: 90%;
    /* min-height: fit-content; */
    min-height: 400px;
  }
  .bannerContentContainerSolution > .leftcont > h1 {
    font-size: 22px;
    margin: 0;
    line-height: normal;
  }
  .leftcont > p {
    font-size: 12px;
    margin-bottom: 15px;
    line-height: normal;
  }
  .leftcont > button {
    width: 142px;
    height: 32px;
    border-radius: 6px;
    font-size: 13px;
    padding: 15px 26px;
  }
  .rightcont > img {
    width: 336px;
    height: 268px;
    border-radius: 8px;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      120px 120px at 30% 50%,
      rgba(234, 169, 229, 0.4) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
  }
}

@media only screen and (max-width: 600px) {
  .bannerContentContainerSolution {
    width: 90%;
    /* border: 1px solid black; */
    flex-direction: column-reverse;
  }
  .bannerContentContainerSolution > .leftcont {
    width: 100%;
    /* border: 1px solid black;    */
  }
  .bannerContentContainerSolution > .rightcont > img {
    width: 90%;
    height: auto;
    border-radius: 8px;
    margin-top: 3%;
  }
  .bannerContentContainerSolution > .rightcont {
    width: 100%;
    margin-bottom: 20px;
  }
  #bg_headingsolution {
    height: fit-content;
    /* background: radial-gradient(
      120px 100px at 20% 50%,
      rgba(234, 169, 229, 0) 10%,
      rgba(217, 217, 217, 0) 90%
    ); */
  }
  .leftcont > button {
    width: 142px;
    height: 32px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 0;
    /* font-size: 1rem; */
    padding: 0px;
  }
  .bannerContentContainerSolution > .leftcont > h1 {
    font-size: 26px;
    text-align: left;
  }
  .leftcont > .leftcont > p {
    font-size: 14px;
  }
}

/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.bannerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../../public/Images/launchpadBanner1/banner1.png),
    lightgray 0px -0.012px / 100% 100.003% no-repeat;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0;
}

.bannerContentContainer {
  width: 1700px;
  height: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.bannerContentContainer h1 {
  max-width: 700px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 68.438px;
  font-style: normal;
  font-weight: 500;
  line-height: 92px; /* 134.429% */
  margin: 0;
}

.bannerContentContainer p {
  max-width: 620px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 18.047px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px; /* 140.19% */
  margin: 0;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .bannerContentContainer {
    width: 1420px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .bannerContentContainer {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .bannerContentContainer {
    width: 950px;
    gap: 40px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .bannerContentContainer {
    width: 90%;
    gap: 40px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .bannerContentContainer {
    width: 90%;
    gap: 20px;
  }
  .bannerContentContainer h1 {
    max-width: 300px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 34.219px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px; /* 134.429% */
    margin: 0;
  }

  .bannerContentContainer p {
    max-width: 300px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }
}

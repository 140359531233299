@font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {
  width: 100%;
  height: max-content;
}

.videoContainer {
  width: 100%;
  height: 700px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* background-color: darkblue; */
}

.videoContainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: -webkit-fill-available;
  object-fit: fill;
  z-index: -100;
}

.contentContainer {
  width: 1700px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  gap: 30px;
}

#head {
  width: 80%;
  color: var(--white, var(--Color, #fff));
  text-align: center;
  font-family: ITC;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#dnt {
  color: var(--white, var(--Color, #fff));
  font-family: ITC;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }

  .videoContainer {
    width: 100%;
    height: 600px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
  .videoContainer {
    width: 100%;
    height: 600px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  .videoContainer {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  .videoContainer {
    width: 100%;
    height: 600px;
  }
  #head {
    font-size: 50px;
  }
  #dnt {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  .videoContainer {
    width: 100%;
    height: 400px;
  }
  #head {
    font-size: 30px;
  }
  #dnt {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
}

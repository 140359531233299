.mapcontlocations {
  /* border: 2px solid green; */
  padding-top: 30px;
  padding-bottom: 42px;
  width: 100%;
  min-height: 340px;
  position: relative;
  z-index: 0;
}

/* our locataion */
.maplocationshead {
  width: 100%;
  /* margin: 0 auto; */
  padding-left: 6.5%;
  padding-bottom: 2vh;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 30px;
}

.mapcontent1 {
  border-radius: 16px;
  border: 1px solid #d3d3d3;
  background: rgba(255, 255, 255, 0.5);
  height: 180px;
  width: 88%;
  display: flex;
  column-gap: 1%;
  padding-right: 2%;
  margin-left: 5%;
  margin-right: 6%;
}

/* location-logo */
.mapcontent1section1 {
  height: 180px;
  min-width: 10%;
  /* padding-top: 4vh; */
  padding-left: 2vw;
  color: #9a92f9;
}

.mapcontent1section2 {
  min-width: 80%;
  height: 180px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
}

.section2bottom {
  display: flex;
  justify-content: flex-start;
  column-gap: 2vw;
}

.section2head {
  width: 100%;
  text-align: left;
  color: #1a1a1a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.section2desc {
  width: 100%;
  text-align: left;
  color: #1a1a1a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section2bottom {
  width: 100%;
  text-align: left;
  color: #404040;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #404040;
}

/* .section2bottom > div:last-child { */

@media only screen and (max-width: 2600px) and (min-width: 1800px) {
  .mapcontlocations {
    display: none;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .mapcontlocations {
    display: none;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .mapcontlocations {
    display: none;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 801px) {
  .mapcontlocations {
    display: none;
  }
}

@media screen and (max-width: 800px) and (min-width: 620px) {
  .mapcontent1 {
    display: flex;
    justify-content: center;
    margin-left: 6%;
  }

  .mapcontent1section1 {
    padding-top: 2.5vh;
    padding-left: 4vw;
  }
  .mapcontent1 {
    column-gap: 3%;
  }

  .section2head {
    font-size: 1.5rem;
  }

  .section2desc {
    font-size: 1rem;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
      background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 620px) and (min-width: 552px) {
  .mapcontent1 {
    display: flex;
    justify-content: center;
    margin-left: 6%;
  }

  .mapcontent1section1 {
    padding-top: 3.2vh;
    padding-left: 2vw;
  }
  /* 
  .mapcontent1section1 Img {
    width: 60px;
    height: 49px;
  } */

  .mapcontent1 {
    column-gap: 2.5%;
  }

  .section2head {
    font-size: 1.2rem;
  }

  .section2desc {
    font-size: o.8rem;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 552px) and (min-width: 480px) {
  .mapcontent1 {
    display: flex;
    justify-content: center;
    margin-left: 6%;
  }

  .mapcontent1section1 {
    padding-top: 3vh;
    padding-left: 2vw;
  }
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 480px) and (min-width: 320px) {
  .mapcontent1 {
    display: flex;
    justify-content: center;
    margin-left: 6%;
  }

  .mapcontent1section1 {
    padding-top: 3vh;
    padding-left: 2vw;
  }
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    /* background-color: #070ad1; */
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

@media screen and (max-width: 320px) {

  .mapcontent1 {
    column-gap: 1%;
    padding-right: 2%;
    margin-left: 5%;
  }

  .mapcontent1section1 {
    padding-top: 3vh;
    padding-left: 2vw;
  }
  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .dotemployee {
    width: 12px;
    height: 12px;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
  }

  .dotemployee.active {
    background-color: #992e95;
    opacity: 1;
  }

  .pagination-dotsemployee {
    display: flex;
    justify-content: center;
    margin-top: 3px;
  }
}

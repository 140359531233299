@font-face {
    font-family: ITC;
    src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
      format("truetype");
  }
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
}

.contentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  width: 100%;
  display: flex;
  justify-content: start;
}
.title > h1 {
  color: #474747;
  font-family: ITC;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* letter-spacing: -0.66px; */
  letter-spacing: 1px;
}

.mainContent {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border: 2px solid blue; */
}

.item {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textStyling {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;

  padding-left: 15%;
  height: 20px;
}
.digitsAndSymbol {
  /* border: 2px solid black; */
  display: flex;
  justify-content: space-evenly;
}

.numbers {
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-top: 0px;
  padding-right: 25%;
}

.symbol {
  color: #4c4c4c;
  text-align: center;
  font-family: ITC;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
}

.textTotal {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.numbersTotal {
  color: #ffbc35;
  text-align: center;
  font-family: ITC;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-top: 0px;
  padding-left: 12%;
}

@media only screen and (max-width: 2600px) and (min-width: 1801px) {
  .contentContainer {
    width: 1700px;
    /* width: 85%; */
    display: flex !important;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    /* width: 1420px; */
    width: 85%;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    /* width: 1200px; */
    width: 85% !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    /* width: 950px; */
    width: 90%;
  }
}

@media screen and (max-width: 1080px) and (min-width: 880px) {
  .contentContainer {
    width: 90%;
  }

  .title > h1 {
    font-size: 18px;
    letter-spacing: -0.66px;
  }

  .mainContent {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .textStyling {
    font-size: 14px;
    text-align: start;
    padding-left: 10%;
    height: 35px;
  }

  .textTotal {
    height: 35px;
  }
  .digitsAndSymbol {
    display: flex;
    justify-content: space-evenly;
  }

  .numbers {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-top: 0px;
    padding-right: 12%;
  }

  .symbol {
    font-size: 24px;
    margin-top: 0px;
    text-align: center;
  }

  .numbersTotal {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1.6px;
    margin-top: 0px;
    padding-left: 12%;
    text-align: center;
  }
}

@media screen and (max-width: 880px) and (min-width: 680px) {
  .contentContainer {
    width: 90%;
  }

  .title > h1 {
    font-size: 18px;
    letter-spacing: 1px;
  }

  .mainContent {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .textStyling {
    font-size: 14px;
    text-align: start;
    padding-left: 8%;
    height: 37px;
  }

  .textTotal {
    height: 37px;
  }

  .digitsAndSymbol {
    display: flex;
    justify-content: space-evenly;
  }

  .numbers {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0px;
    padding-right: 12%;
  }

  .symbol {
    font-size: 18px;
    margin-top: 0px;
    text-align: center;
  }

  .numbersTotal {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1.6px;
    margin-top: 0px;
    padding-left: 12%;
    text-align: center;
  }
}

@media screen and (max-width: 679px) and (min-width: 480px) {
  .contentContainer {
    width: 90%;
  }

  .title > h1 {
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .mainContent {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .textStyling {
    font-size: 14px;
    text-align: start;
    padding-left: 5%;
    height: 36px;
  }

  .textTotal {
    height: 36px;
  }

  .digitsAndSymbol {
    display: flex;
    justify-content: space-evenly;
  }

  .numbers {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
    padding-right: 12%;
  }

  .symbol {
    font-size: 15px;
    margin-top: 0px;
    text-align: center;
  }

  .numbersTotal {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.6px;
    margin-top: 0px;
    padding-left: 12%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .contentContainer {
    width: 90%;
  }

  .title > h1 {
    font-size: 16px;
    letter-spacing: 0px;
    width: 95%;
  }

  .mainContent {
    /* border: 2px solid black; */

    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .item {
    /* border: 2px solid red; */
    display: flex !important;
    margin-bottom: 2%;
    flex-direction: row !important;
  }
  .textStyling {
    /* border: 2px solid black; */
    width: 50%;

    font-size: 14px;
    text-align: start;
    padding: 0px 2px;
    text-align: center;
  }

  .digitsAndSymbol {
    /* border: 2px solid blue; */
    width: 50%;

    display: flex;
    flex-direction: column;
    margin-top: 1%;
  }

  .numbers {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0px;
    padding-right: 12%;
  }

  /* .symbol {
    font-size: 16px;
    margin-top: 0px;
    text-align: center;
  } */

  .textTotal {
    width: 50%;
  }

  .numbersTotal {
    font-size: 16px;
    font-weight: 500; 
    letter-spacing: 1px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }

  .symbolMobile{
    display: none;
  }
}

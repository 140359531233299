/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.questionsContainer {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100%;
}

.questionsContentCont {
  width: 1700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  align-items: flex-start;
  gap: 20px;
}

.questionsContentCont h6 {
  max-height: 41.4px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 30.938px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.4px; /* 133.818% */
  margin: 0;
}

.questionsContentCont p {
  max-height: 20.7px;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px; /* 140.19% */
  margin: 0;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .questionsContentCont {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .questionsContentCont {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .questionsContentCont {
    width: 950px;
  }
}

@media only screen and (max-width: 980px) and (min-width: 601px) {
  .questionsContentCont {
    width: 90%;
  }
}

@media only screen and (max-width: 601px) and (min-width: 320px) {
  .questionsContentCont {
    width: 90%;
  }
  .questionsContentCont h6 {
    max-height: 29.9px;
    color: #4c4c4c;
    font-family: ITC;
    font-size: 22.344px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.9px;
    margin: 0;
  }

  .questionsContentCont p {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 13.125px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }
}

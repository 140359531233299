/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.performanceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  padding: 90px 0px 50px 0px;
  background: url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FPerformanceMetrics%2Fbg.webp?alt=media&token=f1cb51bf-4c72-4fe9-a9c1-56315c209c76");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 840px;
}

.performanceContentCont {
  width: 1700px;
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.performanceTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.performanceTop > p:first-child {
  max-width: 1100px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 68.906px;
  font-style: normal;
  font-weight: 500;
  line-height: 150px;
  margin: 0;
}
.performanceTop > p:last-child {
  max-width: 1100px;
  max-height: 25.3px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 18.047px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px; /* 140.19% */
  margin: 0;
}

.performanceBottomImg {
  display: flex;
  height: 540px;
  padding: 40px 0px 50px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .performanceContentCont {
    width: 1420px;
    height: 724px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .performanceContentCont {
    width: 1200px;
    height: 650px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .performanceContentCont {
    width: 950px;
    height: 600px;
  }
  .performanceBottomImg {
    display: flex;
    width: 100%;
    height: 540px;
    padding: 40px 0px 50px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .performanceContentCont {
    width: 90%;
    height: auto;
  }

  .performanceTop > p:first-child {
    max-width: 700px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 69.063px;
    font-style: normal;
    font-weight: 500;
    line-height: 92px; /* 133.213% */
    margin: 0;
  }
  .performanceTop > p:last-child {
    max-width: 700px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 18.219px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.3px; /* 138.868% */
    margin: 0;
  }
  .performanceBottomImg {
    display: flex;
    width: 100%;
    height: 400px;
    padding: 40px 0px 50px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .performanceContainer {
    padding: 30px 0;
  }
  .performanceContentCont {
    width: 90%;
  }

  .performanceTop > p:first-child {
    max-width: 342px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 34.531px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px; /* 133.213% */
    margin: 0;
  }
  .performanceTop > p:last-child {
    max-width: 342px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }
  .performanceBottomImg {
    display: flex;
    /* width: 100%; */
    width: 342px;
    height: 540px;
    padding: 40px 0px 50px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    object-fit: contain;
  }
}

@media screen and (max-width: 342px) and (min-width: 320px) {
  .performanceTop > p:first-child {
    max-width: 300px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 34.531px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px; /* 133.213% */
    margin: 0;
  }
  .performanceTop > p:last-child {
    max-width: 300px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }
  .performanceBottomImg {
    display: flex;
    /* width: 100%; */
    width: 310px;
    height: 540px;
    padding: 40px 0px 50px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    object-fit: contain;
  }
}

/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.newsletterContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletterContentContainer {
  width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.newsletterTop {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 40px 0px;
}

.newsletterTop img {
  width: 24px;
  height: 25px;
}
.newsletterTop p {
  color: #8f96a1;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.1px;
}

.newsletterBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.newsletterLeft {
  display: flex;
  width: 700px;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: linear-gradient(277deg, #006cbb 1.18%, #3bacff 100.02%);
}

.newsletterLeftPara {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.newsletterLeftPara p:first-child {
  max-width: 600px;
  max-height: 32.2px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 24.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.2px;
}
.newsletterLeftPara p:last-child {
  max-width: 500px;
  max-height: 25.3px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 18.219px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px;
}

.newsletterLeftInputCont {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: 5%;
  gap: 10px;
}

.newletterLeftInput {
  display: flex;
  gap: 5px;
}

.newletterLeftInput input {
  width: 300px;
  height: auto;
  display: flex;
  padding: 13px 10px;
  align-items: flex-start;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
  border: none;
}

.newsletterLeftInputBtn {
  display: flex;
  width: 300px;
}

.newsletterLeftInputBtn input {
  width: 250px;
  height: auto;
  display: flex;
  padding: 13px 10px;
  align-items: flex-start;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15) inset;
  border: none;
}

.newletterLeftInput input::placeholder {
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 14.906px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newsletterLeftInputBtn input::placeholder {
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 14.906px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.newletterLeftBtn {
  border-radius: 0px 5px 5px 0px;
  background: var(--Color, #fff);
  display: flex;
  padding: 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  width: 50px;
  height: auto;
}

.newletterLeftBtn img {
  width: 25px;
  height: 24px;
}

.newsletterRight {
  display: flex;
  width: 700px;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 10px;
  background: linear-gradient(278deg, #ffbc48 0.95%, #e29104 105.61%);
}

.newsletterRightPara {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.newsletterRightPara p:first-child {
  max-width: 500px;
  max-height: 32.2px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 24.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.2px;
}

.newsletterRightPara p:last-child {
  max-width: 500px;
  max-height: 25.3px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 18.219px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px;
}

.newsletterRightSocial {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  margin-top: 3%;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .newsletterContentContainer {
    width: 1420px;
  }
  .newsletterLeft {
    display: flex;
    width: 700px;
    padding: 40px;
  }
  .newsletterRight {
    display: flex;
    width: 700px;
    padding: 40px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .newsletterContentContainer {
    width: 1200px;
  }
  .newsletterLeft {
    display: flex;
    width: 600px;
    padding: 40px;
  }
  .newsletterLeftPara p:first-child {
    max-width: 600px;
    max-height: 32.2px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 24.063px;
    font-style: normal;
    font-weight: 500;
    line-height: 32.2px;
  }
  .newsletterLeftPara p:last-child {
    max-width: 500px;
    max-height: 25.3px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 18.219px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.3px;
  }
  .newsletterRight {
    display: flex;
    width: 600px;
    padding: 40px;
  }
  .newletterLeftInput input {
    width: 250px;
  }

  .newsletterLeftInputBtn {
    width: 250px;
  }

  .newsletterLeftInputBtn input {
    width: 200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .newsletterContentContainer {
    width: 950px;
  }
  .newsletterLeft {
    display: flex;
    width: 450px;
    height: auto;
    padding: 40px;
  }
  .newsletterLeftPara {
    gap: 5px;
  }

  .newsletterLeftPara p:first-child {
    font-size: 20px;
  }
  .newsletterLeftPara p:last-child {
    font-size: 15px;
  }
  .newsletterLeftInputCont {
    margin-top: 40px;
  }
  .newsletterRight {
    display: flex;
    width: 450px;
    height: auto;
    padding: 20px;
  }

  .newletterLeftInput input {
    width: 200px;
    height: 50px;
  }

  .newsletterLeftInputBtn {
    width: 200px;
    height: 50px;
  }

  .newsletterLeftInputBtn input {
    width: 150px;
    height: 50px;
  }

  .newletterLeftBtn {
    width: 50px;
    height: 50px;
    padding: 12px 12px;
  }
  .newsletterRightPara p:first-child {
    font-size: 19px;
  }

  .newsletterRightPara p:last-child {
    font-size: 16px;
  }

  .newsletterRightSocial {
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 0%;
  }
}

@media screen and (max-width: 979px) and (min-width: 601px) {
  .newsletterContentContainer {
    width: 90%;
  }
  .newsletterBottom {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .newsletterLeft {
    display: flex;
    width: 100%;
    height: 250px;
    padding: 0px;
  }

  .newsletterRight {
    display: flex;
    width: 100%;
    height: 250px;
    padding: 0px;
  }
}

@media screen and (max-width: 699px) and (min-width: 601px) {
  .newletterLeftInput input {
    width: 250px;
    height: 50px;
  }

  .newsletterLeftInputBtn {
    width: 250px;
    height: 50px;
  }

  .newsletterLeftInputBtn input {
    width: 200px;
    height: 50px;
  }

  .newletterLeftBtn {
    width: 50px;
    height: 50px;
    padding: 16px 10px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .newsletterContentContainer {
    width: 90%;
  }

  .newsletterTop p {
    font-size: 14px;
  }
  .newsletterBottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .newsletterLeft {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    height: auto;
    padding: 30px;
  }

  .newsletterLeftPara {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .newsletterLeftPara p:first-child {
    max-width: 292px;
    max-height: 25.3px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 18.906px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.3px;
    text-align: start;
    margin: 0;
  }
  .newsletterLeftPara p:last-child {
    max-width: 292px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
    text-align: justify;
    margin: 0;
  }

  .newsletterLeftInputCont {
    margin-top: 0px;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .newletterLeftInput input {
    width: 100%;
    height: auto;
  }

  .newsletterLeftInputBtn {
    width: 100%;
    height: auto;
  }

  .newsletterLeftInputBtn input {
    width: 100%;
    height: auto;
  }

  .newletterLeftBtn {
    width: 50px;
    height: auto;
    padding: 14px 10px;
  }

  .newletterLeftInput input::placeholder {
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 13.125px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .newsletterRight {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    height: auto;
    padding: 30px;
  }

  .newsletterRightPara {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .newsletterRightPara p:first-child {
    max-width: 292px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 18.906px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: justify;
    margin: 0;
  }

  .newsletterRightPara p:last-child {
    max-width: 292px;
    max-height: 20.7px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 14.906px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
    text-align: start;
    margin: 0;
  }
  .newsletterRightSocial {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    height: 100px;
    gap: 20px;
    margin-top: 20px;
  }
  .newsletterRightSocial img {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 349px) and (min-width: 320px) {
  .newsletterLeftPara p:first-child {
    font-size: 16px;
  }
  .newsletterLeftPara p:last-child {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .newsletterRightPara p:first-child {
    font-size: 16px;
  }

  .newsletterRightPara p:last-child {
    font-size: 14px;
  }
  .newsletterRightSocial {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    height: 100px;
    gap: 10px;
    margin-top: 20px;
  }
}

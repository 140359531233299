@font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {
  background: var(--Surface-Primary, #d8efff);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 4% 0; */
  margin-top: 4%;
}
.texts {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2% 0;
}

.texts > h1 {
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 41.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  width: 60%;
}

.texts > p {
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
  width: 60%;
}

.contentContainer {
  /* border: 5px solid green; */
  width: 1700px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 5% 0 !important;
}

.accordionContent {
  width: 95%;
  /* border: 2px solid red; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.singleAccordion {
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  width: 98%;
  border-radius: 10px;
  background: var(--Color, #fff);
  padding: 20px 30px;
  align-items: flex-start;
  align-self: stretch;
  margin: 1% 0;
  padding: 16px 8px;
}

.AccordianHeader {
  /* border: 3px solid blue; */

  width: 98%;
  display: flex;
  justify-content: space-between;
}

.logo  img {
  width: 50px;
  height: 51px;
  padding-left: 2%;
}

.accordianHeaderTitle {
  /* border: 3px solid green; */
  color: #4c4c4c;
  font-family: ITC;
  font-size: 24.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.2px;
  width: 90%;
  display: flex;
  align-items: center;
}

.dropdownImage {
  height: 34px;
  min-height: 34px;
}

.AccordionItem {
  /* border: 5px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-top: 1px solid #f9f9f9;
  color: #8f96a1;
  font-family: ITC;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-left: 6%;
  padding-right: 2%;
}

.dropdownCondition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease-in-out;
}

.dropdownCondition.active {
  max-height: 220px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    /* width: 1420px; */
    width: 80%;
    display: flex !important;
  }
  .texts > h1 {
    width: 80%;
  }

  .texts > p {
    width: 80%;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 80%;
    /* width: 1200px; */
    display: flex !important;
  }
  .texts > h1 {
    width: 80%;
  }

  .texts > p {
    width: 80%;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    /* width: 950px; */
    width: 80%;
    display: flex !important;
  }

  .texts > h1 {
    width: 80%;
  }

  .texts > p {
    width: 80%;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .contentContainer {
    width: 90%;
  }

  .texts > h1 {
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 41.063px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px;
    width: 90%;
  }

  .texts > p {
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
    width: 90%;
  }
}

@media screen and (max-width: 600px) and (min-width: 200px) {
  .container {
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentContainer {
    width: 90%;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 auto;
  }

  .texts > h1 {
    color: var(--Blue, #007cd7);
    font-family: ITC;
    font-size: 27.375px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px;
    width: 90%;
    text-align: start;
  }

  .texts > p {
    color: var(--Blue, #007cd7);
    font-family: ITC;
    font-size: 14.906px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
    width: 90%;
    text-align: start;
  }

  .singleAccordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgba(143, 150, 161, 0.5);
    padding: 12px 0px 13px 0px;
    align-items: flex-start;
    align-self: stretch;
  }

  .AccordianHeader {
    width: 95%;
    display: flex;
    justify-content: space-between;
  }

  .accordianHeaderTitle {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 15.328px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20.7px;
    width: 90%;
  }

  .dropdownImage {
    width: 20px;
    height: 20px;
  }
  .AccordionItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-top: 1px solid #f9f9f9;
    color: #8f96a1;
    font-family: ITC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.4px;
    width: 90%;
    padding-left: 10%;
    padding-right: 2%;
  }
  .dropdownCondition {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out;
  }

  .dropdownCondition.active {
    max-height: 220px;
  }
}

.capcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: red; */
  /* background: linear-gradient(
      108.56deg,
      #bfeeff 0%,
      #ffe6f7 56.93%,
      #ffe6e6 100%
    ); */
  /* border: 1px solid black; */
}

.capContentContainer {
  width: 1700px;
  max-height: fit-content;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 120px;
}

.capContentContainer > h1 {
  display: block;
  font-family: "DM Sans", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: 0em;
  /* text-align: right; */
  margin-bottom: 30px;
}

.capContentContainer > .itemcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 100%;
  gap: 8%;
  /* border: 1px solid black; */
}

.itemcontainer > div {
  width: 28%;
  min-height: 252px;
  /* margin-inline: 2%; */
  margin-bottom: 30px;
  /* margin-bottom: 2%; */
  /* border: 1px solid black; */
}

.itemcontainer > div > div > img {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

.itemcontainer > div > div > h2 {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  height: fit-content;
  /* border: 1px solid black; */
}

.itemcontainer > div > div > p {
  font-family: "DM Sans", sans-serif;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /* width: 400px; */
}

.bgbackCap {
  /* background: radial-gradient(
    300px 200px at 75% 40%,
    rgba(173, 0, 162, 0.1) 0%,
    rgba(217, 217, 217, 0) 100%
  ); */
}

@media only screen and (max-width: 1800px) and (min-width: 1440px) {
  .capContentContainer {
    width: 1420px;
  }
  .bgbackCap {
    /* background: radial-gradient(
      300px 200px at 75% 40%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  .capContentContainer {
    width: 1200px;
  }
  .bgbackCap {
    /* background: radial-gradient(
      300px 200px at 75% 40%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }

  .capContentContainer > h1 {
    font-size: 44px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .capContentContainer {
    width: 950px;
  }
  .bgbackCap {
    /* background: radial-gradient(
      200px 200px at 75% 40%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .capContentContainer > h1 {
    font-size: 38px;
    line-height: 52px;
  }
  .itemcontainer > div > div > img {
    height: 80px;
    width: 80px;
  }
  .itemcontainer > div > div > h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .itemcontainer > div > div > p {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media only screen and (max-width: 980px) {
  .capContentContainer {
    width: 90%;
  }
  .bgbackCap {
    /* background: radial-gradient(
      200px 200px at 75% 40%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .capContentContainer > h1 {
    font-size: 38px;
    line-height: 52px;
  }
  .itemcontainer > div > div > img {
    height: 80px;
    width: 80px;
  }

  .itemcontainer > div > div > h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    min-height:40px;
  }
  .itemcontainer > div > div > p {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .capContentContainer {
    width: 90%;
    padding-bottom: 100px;
  }
  .bgbackCap {
    /* background: radial-gradient(
      150px 200px at 75% 40%,
      rgba(173, 0, 162, 0.15) 0%,
      rgba(217, 217, 217, 0) 100%
    ); */
  }
  .capContentContainer > h1 {
    font-size: 28px;
    line-height: 52px;
  }
  .itemcontainer > div > div > img {
    height: 60px;
    width: 60px;
  }

  .itemcontainer > div > div > h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .itemcontainer > div > div > p {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .capContentContainer {
    width: 90%;
    padding-bottom: 60px;
  }
  .capContentContainer > .itemcontainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    align-items: flex-start;
  }
  .itemcontainer > div {
    width: 100%;
    min-height: 220px;
    margin-bottom: 15px;
  }
  .bgbackCap {
    background: none;
  }
  .itemcontainer > div > div > h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

@font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.viewContainer {
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.viewContentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 5%; */
  gap: 20px;
}

.viewContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 900px;
  height: 300px;
  background: url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimages%2FviewProject%2FviewBg.webp?alt=media&token=23e8c7f4-2d08-43e8-b03f-ecc9235f3d0f"),
    lightgray -44.755px 0px / 109.945% 100% no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  padding: 30px 20px 0 20px;
}

.viewLeft {
}

.viewLeft Img {
  width: 82px;
  height: 82px;
}

.viewCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  /* gap: 50px; */
  gap: 50px;
  align-items: center;
  margin-top: 20px;
}

.viewCenterName {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.viewCenterName h1 {
  max-width: 465px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 40.125px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px; /* 137.57% */
  margin: 0;
}

.viewCenterName p {
  max-width: 465px;
  max-height: 20.7px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20.7px; */
  line-height: 0px;
  letter-spacing: 1px;
  margin: 0;
}

.viewCenterBtn {
  display: flex;
  gap: 10px;
}

.viewCenterBtn > button:first-child {
  width: 220px;
  background: #7869ff;
  display: flex;
  padding: 18px 0px 18px 0px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 15.188px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.7px; /* 136.296% */
  border: none;
  cursor: pointer;
}

.viewCenterBtn > button:last-child {
  width: 220px;
  display: flex;
  padding: 18px 0px 18px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Color, #fff);
  background: var(--Color, #fff);
  color: #01c38d;
  text-align: center;
  font-family: ITC;
  font-size: 15.75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.viewRight Img {
  width: 400px;
  height: 270px;
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .viewContentContainer {
    width: 90%;
  }
  .viewContent {
    width: 700px;
    height: 290px;
  }
  .viewLeft Img {
    width: 75px;
    height: 75px;
  }

  .viewCenter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    gap: 50px;
    align-items: center;
    margin-top: 0px;
  }
  .viewCenterName {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .viewCenterName h1 {
    /* max-width: fit-content; */
    max-width: max-content;
    max-width: 250px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px; /* 137.57% */
    margin: 0;
  }

  .viewCenterName p {
    max-width: 250px;
    max-width: fit-content;
    max-height: 20.7px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 20.7px; */
    line-height: 20px;
    letter-spacing: 0px;
    margin: 0;
    gap: 10px;
  }

  .viewCenterBtn {
    display: flex;
    gap: 10px;
  }

  .viewCenterBtn > button:first-child {
    background: #7869ff;
    display: flex;
    padding: 10px 5px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.7px; /* 136.296% */
    border: none;
    width: 150px;
    height: 40px;
  }

  .viewCenterBtn > button:last-child {
    display: flex;
    padding: 10px 5px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid var(--Color, #fff);
    background: var(--Color, #fff);
    color: #01c38d;
    text-align: center;
    font-family: ITC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    width: 150px;
    height: 40px;
  }

  .viewRight Img {
    width: 250px;
    height: 260px;
  }
}

/*  */

@media screen and (max-width: 600px) and (min-width: 320px) {
  .viewContainer {
    padding: 60px 0px;
  }
  .viewContentContainer {
    width: 90%;
  }
  .viewContent {
    width: 90%;
    height: 500px;
    gap: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
  }
  .viewLeft {
    z-index: 99;
  }
  .viewLeft Img {
    width: 60px;
    height: 60px;
    /* padding-top: 40px; */
  }

  .viewCenter {
    z-index: 99;
  }

  .viewRight Img {
    width: 350px;
    height: 300px;
    position: relative;
    top: -220px;
    left: -10%;
    z-index: 0;
  }
  .viewCenterName h1 {
    font-size: 22px;
    line-height: 40px;
    text-align: justify;
  }
  .viewCenterName p {
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
  }
  .viewCenterBtn {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (max-width: 349px) and (min-width: 320px) {
  .viewContent {
    width: 90%;
    height: 480px;
  }
  .viewRight Img {
    width: 320px;
    height: 300px;
    position: relative;
    top: -240px;
    left: -20%;
    z-index: 0;
  }
}

/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 70px;
}

.mainCont {
  width: 680px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

#mainhead {
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 41.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
}

#para {
  color: #4c4c4c;
  text-align: center;
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    gap: 0px;
    width: 90%;
  }
  #mainhead {
    font-size: 27.375px;
    line-height: 36.8px;
    text-align: left;
    margin-bottom: 0px;
  }
  #para {
    font-size: 13.25px;
    line-height: normal;
    text-align: left;
  }
}

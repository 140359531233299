.parent_features {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5% 0%;
}

.productContentCardWrapper {
  width: 1700px;
}

.productContentCard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.productContentCard:nth-child(even) {
  flex-direction: row-reverse;
}
.productContentCard:nth-child(even) > .productContentCardImage {
  justify-content: center;
}

.productContentCardImage {
  width: 50%;
  display: flex;
  justify-content: center;
}

.productContentCardImage > img {
  width: 60%;
  display: flex;
  min-height: 400px;
  padding: 36px 2.838px 36.001px 2px;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.productContentCardDesc {
  width: 50%;
  padding-left: 8%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.productContentCardDesc > h1 {
  color: #404040;
  font-family: ITC !important;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;

  width: 80%;
  padding-bottom: 0.5rem;
  text-align: left;
}

.productContentCardDesc > p {
  color: #404040;
  text-align: justify;
  font-family: ITC !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  width: 80%;
  padding-top: 0.5rem;
  text-align: left;
}

/* ---fonts setting for desktop---- */
@media only screen and (max-width: 2600px) and (min-width: 1000px) {
  .productContentCardDesc > h1 {
    color: #404040;
    font-family: ITC !important;
    font-size: 26px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
  }
  .productContentCardDesc > p {
    color: #404040;
    text-align: justify;
    font-family: ITC !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

/* ---fonts setting for mobile---- */
@media only screen and (max-width: 768px) and (min-width: 100px) {
  .productContentCardDesc > h1 {
    color: #000;
    text-align: center;
    font-family: ITC !important;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .productContentCardDesc > p {
    color: #000;
    text-align: justify;
    font-family: ITC !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1501px) {
  .productContentCardWrapper {
    width: 1420px !important;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1400px) {
  .productContentCardWrapper {
    width: 1200px !important;
  }

  .productContentCardImage > img {
    width: 60%;
    display: flex;
    min-height: 400px;
    padding: 36px 2.838px 36.001px 2px;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .productContentCardDesc {
    /* width: 50%; */
    padding-left: 2rem;
  }

  .productContentCardDesc > h1 {
    width: 80%;
    padding-bottom: 0.5rem;
    text-align: left;
  }

  .productContentCardDesc > p {
    width: 80%;
    padding-top: 0.5rem;
    text-align: left;
  }
}
@media only screen and (max-width: 1400px) and (min-width: 1300px) {
  .productContentCardWrapper {
    width: 1200px !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1200px) {
  .productContentCardWrapper {
    /* padding: 0 5rem; */
    width: 1100px !important;
  }

  .productContentCardImage > img {
    width: 60%;
    display: flex;
    min-height: 400px;
    padding: 36px 2.838px 36.001px 2px;
  }

  .productContentCardDesc {
    padding-left: 2rem;
  }

  .productContentCardDesc > h1 {

    width: 90%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 90%;
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .productContentCardWrapper {
    width: 950px !important;
  }

  .productContentCardImage > img {
    width: 75%;
    display: flex;
    min-height: 400px;
    padding: 36px 2.838px 36.001px 2px;
  }

  .productContentCardDesc {
    padding-left: 2rem;
  }

  .productContentCardDesc > h1 {
    font-size: 2rem;
    line-height: 40px;
    width: 90%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 90%;

    padding-top: 0.5rem;
    text-align: left;
  }
}

/* --------tablet view-------- */
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 80%;
    display: flex;
    min-height: 400px;
    padding: 36px 2.838px 36.001px 2px;
  }

  .productContentCardDesc {
    padding-left: 2rem;
  }

  .productContentCardDesc > h1 {
    width: 90%;
    padding-bottom: 0.5rem;

    color: #000;
    font-family: ITC !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 32.342px;
  }

  .productContentCardDesc > p {
    color: #000;
    text-align: justify;
    font-family: ITC !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 

    width: 90%;
    padding-top: 0.5rem;
  }
}

/* ------mobile view--------- */
@media screen and (max-width: 768px) and (min-width: 680px) {
  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 100%;
  }

  .productContentCard {
    flex-direction: column;
  }
  .productContentCard:nth-child(2n) {
    flex-direction: column !important;
  }

  .productContentCardImage {
    width: 60% !important;
  }
  .productContentCardDesc {
    width: 90% !important;
    text-align: center !important;
    padding-left: 0 !important;
  }

  .productContentCardDesc > h1 {
    text-align: center !important;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 100%;
    padding-top: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 680px) and (min-width: 580px) {
  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 100%;
  }

  .productContentCard {
    flex-direction: column;
  }
  .productContentCard:nth-child(2n) {
    flex-direction: column !important;
  }

  .productContentCardImage {
    width: 70% !important;
  }
  .productContentCardDesc {
    width: 90% !important;
    text-align: center !important;
    padding-left: 0 !important;
  }

  .productContentCardDesc > h1 {
    text-align: center !important;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 100%;
    padding-top: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 580px) and (min-width: 480px) {
  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 100%;
  }

  .productContentCard {
    flex-direction: column;
  }
  .productContentCard:nth-child(2n) {
    flex-direction: column !important;
  }

  .productContentCardImage {
    width: 75% !important;
  }
  .productContentCardDesc {
    width: 90% !important;
    text-align: center !important;
    padding-left: 0 !important;
  }

  .productContentCardDesc > h1 {
    text-align: center !important;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 100%;
    padding-top: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 480px) and (min-width: 380px) {

  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 100%;
  }

  .productContentCard {
    flex-direction: column;
  }
  .productContentCard:nth-child(2n) {
    flex-direction: column !important;
  }

  .productContentCardImage {
    width: 80% !important;
  }
  .productContentCardDesc {
    width: 90% !important;
    text-align: center !important;
    padding-left: 0 !important;
  }

  .productContentCardDesc > h1 {
    text-align: center !important;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 100%;
    padding-top: 0.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 380px) and (min-width: 100px) {
 

  .productContentCardWrapper {
    width: 90% !important;
  }

  .productContentCardImage > img {
    width: 100%;
  }

  .productContentCard {
    flex-direction: column;
  }
  .productContentCard:nth-child(2n) {
    flex-direction: column !important;
  }

  .productContentCardImage {
    width: 80% !important;
  }
  .productContentCardDesc {
    width: 90% !important;
    text-align: center !important;
    padding-left: 0 !important;
  }

  .productContentCardDesc > h1 {
    text-align: center !important;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .productContentCardDesc > p {
    width: 100%;
    padding-top: 0.5rem;
    text-align: center;
  }
}


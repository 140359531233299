/* @font-face {

  font-family: 'ITC';
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Medium') url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");

} */
/* @font-face {
  font-family: 'ITC' !important;
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde Gothic Medium') url("./fonts/ITC Avant Garde Gothic Medium.otf") format("truetype");
} */
@font-face {
  font-family: 'ITC' !important;
  font-style: normal;
  font-weight: normal;
  src: local('ITC Avant Garde CE Book') url("./fonts/ITC\ Avant\ Garde\ Gothic\ CE\ Book.otf") format("truetype");
}

h1{
  max-height:fit-content !important;
}

*{
  font-family: 'ITC' !important;;
}
/* @font-face {
  font-family: 'Lorin';
  font-style: normal;
  font-weight: normal;
  src: local('Lorin-ExtraBold'), url('./fonts/Lorin-ExtraBold.otf') format('truetype');
} */


body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'ITC' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw !important;
  overflow-x: hidden !important;

}

code {

  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'ITC' !important;

}

.swiper-pagination{
  display: none;
}
.Form_modalFormStyle__nElFB{
  border: none;
  outline: none;
}
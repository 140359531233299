/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mainCont {
  width: 1195px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 60px;
}

.mainCont > .leftCont {
  width: 545px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainCont > .leftCont > img {
  width: inherit;
  height: inherit;
}

.mainCont > .rightCont {
  width: 532px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

#head {
  width: 100%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 30.516px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.4px;
  margin-bottom: 18px;
}

#content {
  width: 100%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px;
  border-radius: 5px;
  color: var(--Color, #fff);
  text-align: center;
  font-family: ITC;
  font-size: 15.469px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.7px; 
  
  margin: 5% 0;
  border: none;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }
  .mainCont {
    width: 999px;
    height: 455px;
  }
  .mainCont > .leftCont {
    width: 455px;
  }
  .mainCont > .rightCont {
    width: 444px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
  .mainCont {
    width: 900px;
    height: 440px;
  }
  .mainCont > .leftCont {
    width: 410px;
  }
  .mainCont > .rightCont {
    width: 400px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  .mainCont {
    width: 844px;
    height: 385px;
  }
  .mainCont > .leftCont {
    width: 385px;
  }
  .mainCont > .rightCont {
    width: 375px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    width: 100%;
    height: 326px;
    margin-top: 30px;
    margin-bottom: 35px;
    gap: 8%;
  }
  .mainCont > .leftCont {
    width: 46%;
  }
  .mainCont > .rightCont {
    width: 46%;
  }
  .mainCont > .leftCont > img {
    width: 100%;
  }
  #head {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 30.516px;
    font-style: normal;
    font-weight: 500;
    line-height: 41.4px;
  }
  #content {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    width: 100%;
    flex-direction: column !important;
    height: auto;
    margin-top: 30px;
    margin-bottom: 64px;
    gap: 40px;
  }
  .mainCont > .leftCont {
    width: 100%;
  }
  .mainCont > .rightCont {
    width: 100%;
  }
  .mainCont > .leftCont > img {
    width: 100%;
  }
  #head {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 22.141px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.9px;
  }
  #content {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

@font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 120px;
}

#mainhead {
  color: var(--Blue, #007cd7);
  font-family: ITC;
  font-size: 40.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  margin-bottom: 60px;
}

.mainCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsContainer {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: auto;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 320px;
  height: 320px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 3px 16px 0px rgba(25, 30, 41, 0.1);
}

.topCont {
  width: 100%;
  height: 150px;
}

.topCont > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottomCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 10px;
}

#date {
  width: 90%;
  color: #8f96a1;
  font-family: ITC;
  font-size: 14.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#heading {
  width: 90%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 23.406px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.2px;
  margin-bottom: 10px;
}

#para {
  width: 90%;
  color: #4c4c4c;
  font-family: ITC;
  font-size: 14.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  .card {
    width: 300px;
    height: 330px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
  }
  .card {
    width: 30%;
    height: 330px;
  }
  .bottomCont {
    padding: 5px;
  }
}

@media screen and (max-width: 767px) {
  .card {
    width: 30%;
    height: 400px;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
  }
  .mainCont {
    width: 100%;
    overflow-x: scroll;
  }
  .cardsContainer {
    margin-left: 100%;
  }
  .card {
    width: 250px;
    height: 350px;
  }
  .bottomCont {
    padding: 5px;
  }
  #mainhead {
    font-size: 27px;
    line-height: 36px;
  }
}

@media screen and (max-width: 450px) {
  .cardsContainer {
    margin-left: 150%;
  }
}

@media screen and (max-width: 374px) {
  .cardsContainer {
    margin-left: 180%;
  }
}

.awardsswipercard {
  width: 90%;
  height: 300px;
  /* border: 2px solid black; */
}
.imagecont {
  width: 100%;
  height: 150px;
  justify-content: center;
  /* border: 2px solid black; */
}
.imagecont > img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.awardtitle {
  width: 100%;
  color: #171717;
  font-family: DM Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* letter-spacing: -0.63px; */

  text-align: center;
  height: fit-content;
}
.awardyear {
  color: #545454;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* letter-spacing: -0.54px; */

  height: fit-content;
  text-align: center;
  width: 100%;
}

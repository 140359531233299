/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #007cd7 0%, #191e29 100%);
}

.contentContainer {
  width: 1700px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 180px;
}

.fdiv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.fdiv > img {
  width: 100px;
  height: 100px;
}

#mainHead {
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 66.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 92px;
}

#para {
  width: 600px;
  display: block;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 17.875px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px;
  text-align: left;
  margin-left: 120px;
}

.dataCont {
  display: flex;
  width: 650px;
  margin-left: 120px;
  margin-top: 40px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.dataCont1 {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#datanum {
  color: var(--Yellow, #ffa200);
  font-family: ITC;
  font-size: 37.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 55.2px;
}

#dataname {
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 14.906px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
}

.btmcont {
  margin-left: 120px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 11.484px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.1px;
}

.rightCont > img {
  width: 700px;
  height: 664px;
}

.leftCont {
  width: max-content;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    width: 1420px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .leftCont {
    width: max-content;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    width: 1420px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .leftCont {
    width: max-content;
  }
  .rightCont {
    width: max-content;
  }
  .rightCont > img {
    width: 500px;
    height: 464px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    width: 950px;
  }
  .leftCont {
    width: 50%;
  }
  .rightCont {
    width: max-content;
  }
  .rightCont > img {
    width: 500px;
    height: 464px;
  }
  #para {
    width: 100%;
  }
  .dataCont {
    width: 100%;
  }
  .dataCont1 {
    width: 120px;
  }
  #datanum {
    font-family: ITC;
    font-size: 30px;
    line-height: 37px;
  }

  #dataname {
    font-size: 12.906px;
    font-weight: 400;
    line-height: 18.7px;
  }
}

@media screen and (max-width: 980px) {
  .contentContainer {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 200px;
  }
  .leftCont {
    width: 50%;
    margin-left: 100px;
  }
  .rightCont {
    width: max-content;
    position: relative;
    top: 250px;
    left: -40px;
  }
  .rightCont > img {
    width: 400px;
    height: 464px;
  }
  #para {
    width: 500px;
    margin-left: 0px;
    margin-top: 20px;
  }
  .dataCont {
    width: 500px;
    margin-left: 0px;
  }
  .dataCont1 {
    width: 120px;
  }
  #datanum {
    font-family: ITC;
    font-size: 30px;
    line-height: 37px;
  }

  #dataname {
    font-size: 12.906px;
    font-weight: 400;
    line-height: 18.7px;
  }
  .fdiv {
    width: 600px;
  }
  .btmcont {
    width: 360px;
    margin-left: 0;
  }
  #mainHead {
    font-size: 50px;
    line-height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 200px;
  }
  .leftCont {
    width: 50%;
    margin-left: 200px;
  }
  .rightCont {
    width: max-content;
    position: relative;
    top: 250px;
    left: -180px;
  }
  .rightCont > img {
    width: 400px;
    height: 464px;
  }
  #para {
    width: 310px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
  .dataCont {
    width: max-content;
    margin-left: 0px;
    flex-direction: column;
  }
  .dataCont1 {
    width: max-content;
  }
  #datanum {
    font-family: ITC;
    font-size: 30px;
    line-height: 37px;
  }

  #dataname {
    font-size: 12.906px;
    font-weight: 400;
    line-height: 18.7px;
  }
  .fdiv {
    width: 310px;
    gap: 12px;
  }
  .btmcont {
    width: 200px;
    margin-left: 0;
  }
  #mainHead {
    font-size: 33px;
    line-height: 47px;
  }
}


@media screen and (max-width: 400px) {
  .contentContainer {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 200px;
  }
  .leftCont {
    width: 50%;
    margin-left: 250px;
  }
  .rightCont {
    width: max-content;
    position: relative;
    top: 250px;
    left: -180px;
  }
  .rightCont > img {
    width: 400px;
    height: 464px;
  }
  #para {
    width: 310px;
    margin-left: 0px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
  .dataCont {
    width: max-content;
    margin-left: 0px;
    flex-direction: column;
  }
  .dataCont1 {
    width: max-content;
  }
  #datanum {
    font-family: ITC;
    font-size: 30px;
    line-height: 37px;
  }

  #dataname {
    font-size: 12.906px;
    font-weight: 400;
    line-height: 18.7px;
  }
  .fdiv {
    width: 310px;
    gap: 12px;
  }
  .btmcont {
    width: 100px;
    margin-left: 0;
    font-size: 10px;
  }
  #mainHead {
    font-size: 33px;
    line-height: 47px;
  }
}
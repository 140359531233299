/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.thematics {
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--Surface-Primary, #d8efff);
  width: 100%;
  padding: 80px 0;
}
.thematicsContainer {
  /* width: 100%; */
  width: 1700px;
}

.thematicsHeader {
  width: 1700px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thematicsHeader h1 {
  max-width: 1180px;
  max-height: 55.2px;
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  margin-bottom: 0;
  margin-top: 0;
}

.thematicsHeader p {
  max-width: 1180px;
  max-height: 20.7px;
  color: var(--Blue, #007cd7);
  text-align: center;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
  /* padding-top: 20px;
  padding-bottom: 40px; */
}

.thematicsFooterContainer {
  width: 1700px;
  display: flex;
  justify-content: space-between;
  /* gap: 5%; */
}

.thematicsFooter {
  width: 380px;
  height: 380px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  border-radius: 10px !important;
  gap: 5%;
  margin-top: 3% !important;
}

.thematicsBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.thematicsBg img {
  width: 380px;
  height: 380px;
  border-radius: 10px !important;
}

.thematicsLogo {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-top: 5%;
}

.thematicsName {
  max-width: 245px;
  max-height: 32.2px;
  color: var(--swissborg-com-nero, var(--Color, #fff));
  text-align: center;
  font-family: ITC;
  font-size: 24.609px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 32.2px; */
  margin-bottom: 0;
  margin-top: 0;
}

.thematicsDesc {
  /* max-width: 245px; */
  max-width: 320px;
  color: var(--swissborg-com-nero, var(--Color, #fff));
  text-align: center !important;
  font-family: ITC !important;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20.7px; */
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 0;
}

.thematicsIconContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.thematicsIcon {
  width: 50;
  height: 20px;
  flex-shrink: 0;
}

.thematicsCoins {
  display: flex;
  padding: 3px 4px 2px 4px;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  background: var(--Color, #fff);
  color: var(--swissborg-com-golden-grass, #daa421);
  text-align: center;
  font-family: ITC;
  font-size: 12.141px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.thematicsBtnCont {
  width: 200px;
  height: 32px;
  display: inline-flex;
  padding: 9px 17px 9px 17px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--swissborg-com-nero, #fff);
  cursor: pointer;
}

.thematicsBtn {
  color: var(--swissborg-com-nero, var(--Color, #fff));
  text-align: center;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .thematicsContainer {
    width: 1420px;
  }
  .thematicsHeader {
    width: 1420px;
  }
  .thematicsFooterContainer {
    width: 1420px;
    display: flex;
    gap: 3%;
    margin-top: 5%;
  }
  .thematicsFooter {
    width: 360px;
    height: 360px;
    /* width: 340px;
    height: 340px; */
    gap: 6%;
    margin-top: 1% !important;
    padding: 5px;
  }
  .thematicsBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .thematicsBg img {
    width: 100%;
    height: 360px;
  }
  .thematicsDesc {
    max-width: 320px;
    line-height: 20.7px;
  }
  .thematicsBtnCont {
    width: 200px;
    height: 40px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .thematicsContainer {
    width: 1200px;
  }
  .thematicsHeader {
    width: 1200px;
  }
  .thematicsFooterContainer {
    width: 1200px;
    display: flex;
    gap: 3%;
    margin-top: 3%;
  }
  .thematicsFooter {
    width: 340px;
    height: 340px;
    gap: 4%;
  }
  .thematicsBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .thematicsBg img {
    width: 100%;
    height: 340px;
  }
  .thematicsDesc {
    max-width: 260px !important;
    line-height: 20.7px;
  }
  .thematicsBtnCont {
    width: 200px;
    height: 40px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .thematicsContainer {
    width: 950px;
  }
  .thematicsHeader {
    width: 950px;
  }
  .thematicsFooterContainer {
    width: 950px;
    display: flex;
    gap: 3%;
    margin-top: 2%;
  }
  .thematicsFooter {
    width: 320px;
    height: 320px;
    gap: 4%;
  }
  .thematicsBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .thematicsBg img {
    width: 100%;
    height: 320px;
  }
  .thematicsDesc {
    max-width: 200px !important;
    line-height: 20.7px;
  }
  .thematicsBtnCont {
    width: 200px;
    height: 40px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .thematics {
    width: 100%;
  }
  .thematicsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thematicsHeader {
    width: 90%;
  }
  .thematicsHeader h1 {
    max-width: 732px;
    max-height: 55.2px;
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 41.063px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px; /* 134.429% */
  }

  .thematicsHeader p {
    max-width: 732px;
    max-height: 20.7px;
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
  }
  .thematicsFooterContainer {
    width: 90%;
    display: flex;
    gap: 3%;
    margin-top: 3%;
  }
  .thematicsFooter {
    width: 200px !important;
    height: 450px;
    gap: 4%;
    padding: 0 10px;
  }

  .thematicsBg img {
    width: 100%;
    height: 450px;
  }
  .thematicsName {
    max-width: 131.36px;
    max-height: 32.2px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 32.2px; */
    margin-bottom: 10px;
    margin-top: 0;
  }

  .thematicsDesc {
    max-width: 200px !important;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 14.906px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .thematicsBtnCont {
    width: 100px;
    height: 30px;
    display: inline-flex;
    padding: 6px 0;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--swissborg-com-nero, #fff);
    cursor: pointer;
  }

  .thematicsBtn {
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media screen and (max-width: 750px) and (min-width: 601px) {
  .thematics {
    width: 100%;
  }
  .thematicsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thematicsHeader {
    width: 90%;
  }
  .thematicsFooterContainer {
    width: 90%;
    display: flex;
    gap: 3%;
  }
  .thematicsFooter {
    width: 200px !important;
    height: 500px;
    gap: 4%;
    margin-top: 1% !important;
    padding: 0 10px;
  }
  .thematicsBg img {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .thematics {
    display: flex;
    display: flex;
    padding: 80px 0px;
    background: var(--Surface-Primary, #d8efff);
    width: 100%;
  }
  .thematicsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .thematicsHeader {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8%;
  }
  .thematicsHeader h1 {
    max-width: 300px;
    /* max-height: 36.8px; */
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 27.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px; /* 133.818% */
    margin-bottom: 20px;
  }

  .thematicsHeader p {
    max-width: 400px;
    color: var(--Blue, #007cd7);
    text-align: center;
    font-family: ITC;
    font-size: 13.125px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .thematicsFooterContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .thematicsFooter {
    /* width: 342px; */
    width: 300px;
    height: 300px;
    gap: 10px;
    margin-top: 1% !important;
    padding: 0 10px;
  }

  .thematicsBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .thematicsBg img {
    width: 100%;
    height: 300px;
  }
  .thematicsName {
    max-width: 310px;
    max-height: 25.3px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 19.25px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.3px; /* 131.429% */
    /* line-height: 32.2px; */
    margin-bottom: 10px;
    margin-top: 0;
  }

  .thematicsDesc {
    max-width: 310px;
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 13.25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
  }
  .thematicsBtnCont {
    width: 150px;
    height: 30px;
    display: inline-flex;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid var(--Color, #fff);
    cursor: pointer;
    margin-top: 10px;
  }

  .thematicsBtn {
    color: var(--Color, #fff);
    text-align: center;
    font-family: ITC;
    font-size: 12.141px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

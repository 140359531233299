.heading{
    color: #474747;
font-family: "ITC";
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.66px;
/* text-align: center; */
/* vertical-align: center; */
}

.heading2{
    color: #474747;
    font-family: "ITC";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.66px;
    display: flex;
    align-items: flex-start;
    gap: 4px; 


}
.inner{
    width: 1700px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cities{
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.blues{
    border-radius: 8px;
border: 1px solid var(--Blue, #007CD7);
display: flex;
padding-left: 4px;
padding-right: 4px;
justify-content: center;
align-items: center;
/* gap: 12px; */
height: 50px;
}

.blues > h1{
    color: var(--Blue, #007CD7);
text-align: center;
font-family: ITC Avant Garde Gothic;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 100% */
margin-bottom: 0;
}
.clicked{
    background-color: #007CD7;
}
.blues:hover{
    cursor: pointer;
}
.clicked1{
    color: white !important;
}
.clickedcl{
    display: block;
    width: 14spx;
    padding-left: 10px;
}

.notClicked{
    display: none;
}

.secondheading{
    display: flex;
    gap: 7px;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
}
.apps{
    display: flex;
    /* width: 1700px; */
    /* justify-content: space-between; */
    gap: 45px;
    align-items: center;
    flex-wrap: wrap;
}
.back{
    border-radius: 4px;
border: 1px solid var(--Color, #FFF);
background: #FFF7EA;
width: 80px;
display: flex;
justify-content: center;
align-items: center;
height: 80px;
}

.clickedapp{
    border-radius: 4px;
border: 1.5px solid #0186FF;
background:  #FFF;
box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 2600px) and (min-width: 1801px) {
    .inner {
      width: 1700px;
      /* width: 85%; */
      /* display: flex !important; */
    }
  }
  
  @media only screen and (max-width: 1800px) and (min-width: 1500px) {
    .inner {
      /* width: 1420px; */
      width: 85%;
    }
  }
  
  @media only screen and (max-width: 1500px) and (min-width: 1250px) {
    .inner {
      /* width: 1200px; */
      width: 85% !important;
    }
  }
  
  @media only screen and (max-width: 1250px) and (min-width: 980px) {
    .inner {
      width: 950px;
    }
  }

  @media only screen and (max-width: 980px)  {
    #container{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .inner{
        width: 90%;
    }
    .apps{
        justify-content: flex-start;
        gap: 25px;
        width: 100%;
        /* margin-left: auto; */
    }
    .cities{
        width: 90%;
        margin-left: auto;
    }
  }

  @media only screen and (max-width: 600px)  {
    .apps{
        justify-content: center;
        width: 100%;
    }
  }
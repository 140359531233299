/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.poppedContainer {
  display: flex;
  width: 100%;
  padding: 120px 0px 120px 0px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: linear-gradient(270deg, #41aeff 5%, #2e7dcd 100%), #007cd7;
  /* height: 200px; */
  margin-top: 10%;
}

.poppedContentCont {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 1700px;
  height: 280px;
}

.poppedLeft {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.poppedLeft h1 {
  max-width: 400px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 41.813px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px; /* 132.018% */
  margin: 0;
}

.poppedLeft p {
  max-width: 400px;
  color: var(--Color, #fff);
  font-family: ITC;
  font-size: 18.047px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px; /* 140.19% */
  margin: 0;
}

.poppedRight Img {
  width: 628.3px;
  height: 600px;
  position: relative;
  top: -72%;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .poppedContentCont {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .poppedContentCont {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .poppedContainer {
    margin-top: 20%;
  }
  .poppedContentCont {
    width: 950px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .poppedContainer {
    margin-bottom: 20%;
  }
  .poppedContentCont {
    width: 90%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .poppedLeft h1 {
    max-width: 300px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
  }

  .poppedLeft p {
    max-width: 300px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 18.047px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.3px; /* 140.19% */
    margin: 0;
  }

  .poppedRight Img {
    width: 420px;
    height: 440px;
    position: relative;
    top: -10%;
  }
}

@media screen and (max-width: 700px) and (min-width: 601px) {
  .poppedLeft h1 {
    font-size: 26px;
  }

  .poppedLeft p {
    font-size: 14px;
  }

  .poppedRight Img {
    width: 350px;
    height: 440px;
    top: -20%;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .poppedContainer {
    padding: 60px 0px 0px 0px;
    margin-top: 0;
  }
  .poppedContentCont {
    width: 90%;
    height: 600px;
    display: flex;
    flex-direction: column;
  }
  .poppedLeft {
    display: flex;
    gap: 50px;
  }
  .poppedLeft h1 {
    max-width: 342px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 27.75px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px; /* 132.613% */
    margin: 0;
    text-align: justify;
  }

  .poppedLeft p {
    max-width: 342px;
    color: var(--Color, #fff);
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }

  .poppedRight Img {
    width: 300px;
    height: 350px;
    position: relative;
    top: 14%;
  }
}

.benefitsContainer {
  position: relative;
  /* overflow: hidden; */
  /* background-color: aquamarine; */
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #e5f2fb;
  padding-bottom: 2%;
}

.decoratorb-1 {
  position: absolute;
  width: 1453.67px;
  height: 1044.65px;
  left: 80%;
  top: -20%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(149, 217, 233, 0.4) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  filter: blur(77px);
  z-index: -1111;
}

.decoratorb-2 {
  position: absolute;
  width: 1172px;
  height: 1098px;
  top: -35%;
  /* left: 10%; */
  right: 80%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(236, 170, 231, 0.5) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  filter: blur(57px);
  z-index: -1111;
}

.benefitsBoxHeading {
  /* position: absolute; */
  width: 1700px;
  text-align: center;
  padding-top: 5rem;
  /* width: 369px;
    left: 40%;
    top: 0px; */
  /* background: #FFFFFF; */
  /* box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15); */
  /* border-radius: 10px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefitsBoxHeading > h1 {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  /* identical to box height, or 98% */
  text-align: right;
  color: #007cd7;
}

.benefitsCardsContainer {
  width: 1700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 7%;
  align-items: center;
  /* margin-top: 5rem; */
  padding-bottom: 3rem;
  padding-top: 1.5rem;
}

.benefitsCardWrapper {
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0rem;
}

.benefitsCardWrapper > img {
  width: 100px;
  height: 100px;
}

.benfitsDetails {
  text-align: center;
}

.benfitsDetails > h1 {
  width: 100%;
  min-height: 50px;
  max-height: 84px;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 49px;
  text-align: center;
  color: #434343;
}

.benfitsDetails > p {
  width: 80%;
  min-height: 50px;
  max-height: max-content;
  margin-left: auto;
  margin-right: auto;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-align: center;
  color: #434343;
}
/* .bg_color_01 {
    background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(170, 153, 239, 0.8) 0%, rgba(217, 217, 217, 0.00) 100%) ;
} */

/* .bg_color_01 {
    background: radial-gradient(10% 10.00% at 70% 50.00%, rgba(170, 153, 239, 0.8) 0%, rgba(217, 217, 217, 0.00) 100%) ;
} */

.bg_color_01 {
  background: radial-gradient(
    18% 18% at 18% 75%,
    rgba(236, 170, 231, 0.9) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}

.benefitsBanner {
  width: 1700px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  column-gap: 0.2rem;
  margin: 1rem;
  margin-bottom: 5rem;
  /* background-color: aquamarine; */
}

.bannerImageContainer {
  min-height: fit-content;
  margin: 3.9rem;
  width: 50%;
  /* background: url("../../../images/platform/PascalIntelligence/Rectangle 4067.png"); */
  background-size: cover;
  display: flex;
  align-items: center;
}

.bannerImageContainer > .bgimg {
  border-radius: 40px;
}

.benefitsBannerDetails {
  width: 50%;
  float: left;
  margin: 1rem;
  /* padding-left: 10rem;  */
}

.benefitsBannerDetails > h1 {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 49px;
  /* or 134% */
  color: #1a1a1a;
}

.benefitsBannerDetails > p {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  /* or 144% */
  /* letter-spacing: -0.03em; */
  color: #1a1a1a;
  padding: 0.8rem 0rem;
}

.benefitsBannerDetails > button {
  width: fit-content;
  border: 2px solid #ad00a2;
  border-radius: 10px;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 0px;
  /* identical to box height, or 0% */
  text-align: center;
  color: #ad00a2;
  padding: 1.5rem;
}

.adBannerContainer {
  height: 28rem;
  background: rgba(179, 173, 250, 0.61);
  display: flex;
  justify-content: center;
  align-items: center;
}

.adBannerWrapper {
  opacity: 0.9;
  backdrop-filter: blur(72px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px;
  height: 22rem;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adBannerWrapper > h1 {
  opacity: 0.9;
  backdrop-filter: blur(72px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px;
  height: 22rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adBannerWrapper > h1 {
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  /* identical to box height, or 114% */
  text-align: center;
  /* letter-spacing: -0.03em; */
  color: #ad00a2;
}

.adBannerWrapper > p {
  padding: 1.5rem 0rem;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  /* identical to box height, or 114% */
  text-align: center;
  /* letter-spacing: -0.03em; */
  color: #ad00a2;
}

.adBannerWrapper > p {
  padding: 1.5rem 0rem;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  /* or 136% */
  text-align: center;
  /* letter-spacing: -0.02em; */
  color: #1a1a1a;
  width: 30%;
}

.adBannerWrapper > button {
  background: #ad00a2;
  border: 2px solid #ad00a2;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 2700px) and (min-width: 1801px) {
  .benfitsDetails > h1 {
    min-height: 85px !important;
    line-height: 41px;
    margin-top: 8%;

    /* max-height:84px ; */
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .benefitsBoxHeading,
  .benefitsCardsContainer,
  .benefitsBanner {
    width: 1420px;
  }
  .benefitsBanner {
    margin-bottom: 5rem !important;
  }
  .benfitsDetails > h1 {
    min-height: 85px !important;
    line-height: 41px;
    margin-top: 8%;
    /* max-height:84px ; */
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .benefitsBoxHeading,
  .benefitsCardsContainer,
  .benefitsBanner {
    width: 1250px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 1000px) {
  .benefitsBoxHeading,
  .benefitsCardsContainer,
  .benefitsBanner {
    width: 950px;
  }
  .benefitsContainer {
    padding-bottom: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .benefitsBoxHeading,
  .benefitsCardsContainer,
  .benefitsBanner {
    width: 90%;
  }
  .benefitsContainer {
    padding-bottom: 10%;
  }
  .benfitsDetails > h1 {
    font-size: 24px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  .bg_color_01 {
    background: none;
  }
}

/* @media screen and (max-width: 800px) {
  benfitsDetails {
    max-height: 70px !important;
  }
} */

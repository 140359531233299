* {
  /* margin: 0; */
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.Landing_page_Container {
  width: 100%;
  scrollbar-width: thin;
  overflow-x: hidden;
}

.Landing_page_Container::-webkit-scrollbar {
  width: 5px !important;
}
/* #mapcomp{
  width: 100vw;
  height: 100vh;
} */
.Landing_top_bg {
  width: 100%;
  min-height: 1000px;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  background: radial-gradient(
    80.96% 80.37% at 55.66% 23.99%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 31.9%,
    rgba(0, 0, 0, 0.8) 91.59%
  );
}

.video-background {
  width: 100%;
  min-height: inherit;
  max-height: fit-content;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-background video {
  /* object-fit: contain; */
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: -webkit-fill-available;
  object-fit: cover;
  -webkit-object-fit: cover;
  -moz-object-fit: cover;
  -o-object-fit: cover;
  z-index: -100;
}

.overlaydiv {
  position: absolute;
  top: 10;
  right: 0;
  width: 68%;
  height: 90%;
  margin-right: 1%;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url(./video/x.png); */
  z-index: 10;
}

.bannerContentContainerland {
  width: 1700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 130px;
  min-height: 1000px;
  max-height: fit-content;
  /* z-index: 1; */
}

.mapbutton {
  z-index: 10;
}

#abcdef > h1 {
  color: white !important;
}
.bannerContentContainerland > h1 {
  color: #f6f6f6;
  text-align: center;
  font-family: "ITC";
  font-size: 11rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150px;
}
/* @font-face {
  font-family: "ITC";
  font-style: normal;
  font-weight: normal;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */
.bannerContentWrapper1 {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bannerContentland {
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
}

.bannerContentland > div > h1 {
  color: #f6f6f6;
  text-align: center;
  font-family: "ITC";
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 1%;
}

.bannerContentland > div > p {
  color: #f6f6f6;
  text-align: center;
  font-family: "ITC";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;
  margin-bottom: 5%;
  margin: 0 auto;
}

.bg_subheading {
  height: fit-content;
}
.bannerContentBtnland {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  color: white;
}

#getStartedBtnLanding {
  width: fit-content;
  box-sizing: border-box;
  padding: 10px 30px 10px 30px;

  background: #ffa200;
  border: 1px solid #ffa200;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
}

#learnMoreBtnLanding {
  color: #fff !important;
  font-family: "ITC";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

#learnMoreBtnLanding:hover {
  cursor: pointer;
}
.nexus_rightarrow img {
  color: white !important;
  fill: white;
}

/* ---------------second_bg_img-contaniner--------------------------- */
.second_bg_img-contaniner {
  background: url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2FlandingImages%2Fside-view-woman.webp?alt=media&token=c894c960-a905-4356-b9b1-fe3f5fb47c35");
  background-size: 100% 100%;
  width: 100%;
  min-height: 500px;
  max-height: fit-content;
  object-fit: cover;
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: flex-start;
  /* background-repeat: no-repeat; */
  /* object-fit: contain; */
}

.second_bg_content {
  width: 1700px;
  min-height: fit-content;
  margin: 6% 0;
}

.second_bg_heading h1 {
  color: #f6f6f6;
  font-family: "ITC";
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 43.191px;

  padding: 1% 0%;
  text-align: left;
}

.second_bg_para1 p {
  width: 55%;

  color: #f6f6f6;
  font-family: "ITC";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 43.191px;
  padding: 1% 0%;
  text-align: left;
}

.second_bg_para2 p {
  width: 55%;
  color: #f6f6f6;
  font-family: "ITC";
  font-style: normal;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

/* third_bg_contaniner--------------------------------------------------- */
.third_bg_contaniner {
  min-height: 700px;
  max-height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.third_bg_content {
  width: 1700px;
  max-height: fit-content;
}

.third_bg_upper {
  min-height: 300px;
  max-height: fit-content;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.third_bg_upper div h1 {
  color: #434343;
  text-align: center;
  font-family: ITC;
  font-size: 40.687px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  text-transform: capitalize;
  text-transform: capitalize;
}

.third_bg_upper div p {
  width: 80%;
  margin: 0 auto;

  color: #434343;
  text-align: center;
  font-family: ITC !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.132px;
}

.Ark_logo {
  width: 142px;
  height: 71px;
  padding: 1%;
  margin-bottom: 2%;
}

.Ark_logo img {
  width: 100%;
}

.mobius_logo_ {
  width: 128px;
  height: 68.741px;
  padding: 1%;
  margin-bottom: 2%;
}

.mobius_logo_ img {
  width: 100%;
}

.third_bg_lower_horizontal {
  /* border: 2px solid blue; */

  min-height: 300px;
  max-height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
  margin-top: 5%;
}

.third_bg_left_content {
  /* border: 2px solid blue; */

  min-height: 300px;
  max-height: fit-content;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.third_bg_lower_para1 p {
  color: #404040;
  text-align: justify;
  font-family: ITC !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.132px;
}

.third_bg_right_content {
  /* border: 2px solid blue; */

  min-height: 300px;
  max-height: fit-content;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.third_bg_lower_para2 p {
  color: #404040;
  text-align: justify;
  font-family: ITC !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.132px;
  line-height: 180%;
}

/* -------------mobius_won_wrapper--------- */
.mobius_won_wrapper {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobius_won_content {
  /* border: 2px solid red; */

  position: relative;
  width: 1700px;
  min-height: 180px;
  max-height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  border: 0.736px solid #ffcc47;
  background: linear-gradient(
    91deg,
    rgba(5, 151, 255, 0.1) -1.56%,
    rgba(255, 205, 130, 0.1) 71.36%,
    rgba(255, 162, 0, 0.1) 101.57%
  );
  margin: 6% auto;
}

.content_nab-show {
  width: 300px;
}

.content_nab-show img {
  /* border: 2px solid red; */

  position: absolute;
  top: -50px;
  left: 60px;
  width: 300px;
  height: 250px;
}

.content_text p {
  /* border: 2px solid red; */

  width: 800px;
  /* width: 95%; */
  color: #404040;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: ITC !important;
  font-size: 34px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: start;
}

.winner_Img {
  /* border: 2px solid red; */

  width: 160px;
  height: 120px;
}

.winner_img img {
  width: 100%;
}

/* ------------------------.android_swiper_heading-------- */
.android_swiper_heading {
  color: #cdcdcd;
  font-family: ITC !important;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

/* --------fonts for desktop---------- */
@media only screen and (max-width: 2600px) and (min-width: 901px) {
  /* ---------------second_bg_img-contaniner--------------------------- */
  .second_bg_heading h1 {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 47.66px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 43.191px;
  }

  .second_bg_para1 p {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 26.809px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 43.191px;
  }

  .second_bg_para2 p {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  /* -------------mobius_won_wrapper--------- */

  .content_text p {
    color: #404040;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: ITC !important;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    text-align: start;
  }
}

/* --------fonts for tablet---------- */
@media only screen and (max-width: 900px) and (min-width: 601px) {
  /* ---------------second_bg_img-contaniner--------------------------- */
  .second_bg_heading h1 {
    color: var(--Color, #fff);
    font-family: ITC !important;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28.041px;
  }

  .second_bg_para1 p {
    color: var(--Color, #fff);
    font-family: ITC !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28.041px;
  }

  .second_bg_para2 p {
    color: var(--Color, #fff);
    text-align: justify;
    font-family: ITC !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  /* -------------mobius_won_wrapper--------- */

  .content_text p {
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: ITC !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25.764px;
  }
}

/* --------fonts for mobile---------- */
@media only screen and (max-width: 600px) and (min-width: 100px) {
  /* ---------------second_bg_img-contaniner--------------------------- */
  .second_bg_heading h1 {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 43.191px;
    margin-bottom: 5px !important;
  }

  .second_bg_para1 p {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .second_bg_para2 p {
    color: #f6f6f6;
    font-family: ITC !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1440px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 1420px;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150px;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerContentland {
    width: 70%;
  }

  .bannerContentland > div > h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 36px;
  }

  .bannerContentland > div > p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 500px;
    max-height: fit-content;
  }

  .second_bg_content {
    width: 1420px !important;
    min-height: fit-content;
  }

  .second_bg_para2 p {
    width: 55%;
  }

  /* third_bg_contaniner--------------------------------------------------- */
  .third_bg_content {
    width: 1420px;
    max-height: fit-content;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 1420px;
    max-height: fit-content;
  }

  .content_text p {
    width: 600px;
    text-align: start;
  }

  .winner_Img {
    width: 160px;
    height: 120px;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1250px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 1200px;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150px;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerContentland {
    width: 70%;
  }

  .bannerContentland > div > h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 36px;
  }

  .bannerContentland > div > p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    /* margin: 0 auto; */
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 320px;
    max-height: fit-content;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 1200px !important;
    min-height: fit-content;
    margin: 1% 0;
    padding-left: 4%;
  }

  .second_bg_para2 p {
    width: 55%;
  }

  /* third_bg_contaniner--------------------------------------------------- */
  .third_bg_content {
    width: 1200px !important;
    max-height: fit-content;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 1200px !important;
    max-height: fit-content;
  }

  .content_nab-show {
    width: 280px;
  }

  .content_nab-show img {
    position: absolute;
    top: -50px;
    left: 40px;
    width: 280px;
    height: 250px;
  }

  .content_text p {
    width: 520px;
    text-align: start;
  }

  .winner_Img {
    width: 160px;
    height: 120px;
  }

  .winner_img img {
    width: 100%;
  }
}

@media screen and (max-width: 1250px) and (min-width: 980px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 950px !important;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150px;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerContentland {
    width: 70%;
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    /* margin: 0 auto; */
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 280px;
    max-height: fit-content;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 950px !important;
    min-height: fit-content;
    margin: 1% 0;
    padding-left: 4%;
  }

  .second_bg_heading h1 {
    /* font-size: 4rem; */
    line-height: 33.191px !important;
  }

  .second_bg_para1 p {
    width: 55%;
    /* font-size: 2.5rem; */
    line-height: 23.191px !important;
  }

  .second_bg_para2 p {
    width: 58%;
    font-size: 0.9rem !important;
    line-height: 22px;
  }

  /* third_bg_contaniner--------------------------------------------------- */
  .third_bg_content {
    width: 950px !important;
    max-height: fit-content;
  }

  .third_bg_right_content {
    min-height: 300px;
    max-height: fit-content;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }

  /* -------------mobius_won_wrapper--------- */

  .mobius_won_content {
    width: 90%;
    min-height: 160px;
    margin: 8% auto;
  }

  .content_nab-show {
    width: 260px;
  }

  .content_nab-show img {
    position: absolute;
    top: -40px;
    left: 20px;
    width: 260px;
    height: 230px;
  }

  .content_text p {
    width: 420px;
  }

  .winner_Img {
    width: 150px;
    height: 90px;
  }

  .winner_img img {
    width: 100%;
  }
}

@media screen and (max-width: 980px) and (min-width: 780px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 950px !important;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150px;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerContentland {
    width: 70%;
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    /* margin: 0 auto; */
  }

  /* ==========secong bg container============ */
  /* .second_bg_img-contaniner {
    min-height: 300px;
    max-height: fit-content;
  }

  .second_bg_content {
    width: 90% !important;
    min-height: fit-content;
    margin: 4% 0;
    padding-left: 4%;
  }

  .second_bg_para2 p {
    width: 55%;
  } */

  /* third_bg_contaniner--------------------------------------------------- */
  .third_bg_content {
    width: 90% !important;
    max-height: fit-content;
  }

  .third_bg_right_content {
    min-height: 300px;
    max-height: fit-content;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
}

@media screen and (max-width: 980px) and (min-width: 880px) {
  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 280px;
    max-height: fit-content;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 950px !important;
    min-height: fit-content;
    margin: 1% 0;
    padding-left: 4%;
  }

  .second_bg_heading h1 {
    /* font-size: 4rem; */
    line-height: 33.191px !important;
  }

  .second_bg_para1 p {
    width: 55%;
    /* font-size: 2.5rem; */
    line-height: 23.191px !important;
  }

  .second_bg_para2 p {
    width: 58%;
    font-size: 0.9rem !important;
    line-height: 22px;
  }
  /* -------------mobius_won_wrapper--------- */

  .mobius_won_content {
    width: 90%;
    min-height: 160px;
    margin: 8% auto;
  }

  .content_nab-show {
    width: 260px;
  }

  .content_nab-show img {
    position: absolute;
    top: -35px;
    left: 20px;
    width: 250px;
    height: 220px;
  }

  .content_text p {
    width: 340px;
    font-family: ITC !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25.764px !important;
  }

  .winner_Img {
    width: 150px;
    height: 80px;
  }

  .winner_img img {
    width: 100%;
  }
}

@media screen and (max-width: 880px) and (min-width: 780px) {
  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 280px;
    max-height: fit-content;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 950px !important;
    min-height: fit-content;
    margin: 1% 0;
    padding-left: 4%;
  }

  .second_bg_heading h1 {
    /* font-size: 4rem; */
    line-height: 33.191px !important;
  }

  .second_bg_para1 p {
    width: 55%;
    /* font-size: 2.5rem; */
    line-height: 23.191px !important;
  }

  .second_bg_para2 p {
    width: 58%;
    font-size: 0.9rem !important;
    line-height: 22px;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 160px;
    margin: 8% auto;
  }

  .content_nab-show {
    width: 260px;
  }

  .content_nab-show img {
    position: absolute;
    top: -35px;
    left: 20px;
    width: 230px;
    height: 210px;
  }

  .content_text p {
    width: 300px;
    font-family: ITC !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 25.764px !important;
  }

  .winner_Img {
    width: 100px;
    height: 70px;
  }

  .winner_Img img {
    width: 90% !important;
  }
}

@media screen and (max-width: 780px) and (min-width: 680px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 950px !important;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100px;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bannerContentland {
    width: 70%;
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    /* margin: 0 auto; */
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 265px;
    max-height: fit-content;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 90% !important;
    min-height: fit-content;
    margin: 4% 0;
    padding-left: 3%;
  }

  .second_bg_para2 p {
    width: 55%;
  }

  /* third_bg_contaniner--------------------------------------------------- */

  /* width resolution ------------ */
  .third_bg_content {
    width: 90% !important;
    max-height: fit-content;
  }

  .third_bg_right_content {
    min-height: 300px;
    max-height: fit-content;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 140px;
    margin: 8% auto;
  }

  .content_nab-show {
    width: 240px;
  }

  .content_nab-show img {
    position: absolute;
    top: -35px;
    left: 20px;
    width: 210px;
    height: 200px;
  }

  .content_text p {
    width: 300px;
    font-family: ITC !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 22.764px !important;
  }

  .winner_Img {
    width: 100px;
    height: 70px;
  }

  .winner_Img img {
    width: 90% !important;
  }

  .android_swiper_heading h1 {
    font-size: 32px;
  }
}

/* ----mobile view------------ */
@media screen and (max-width: 600px) and (min-width: 100px) {
  /* .mobile_view_Img {
    display: block !important;
    padding-top: 100px;
    
  }

  .mobile_view_img img {
    width: 100%;
    height: 250px;
  } */

  /* third_bg_contaniner--------------------------------------------------- */
  .third_bg_contaniner {
    min-height: fit-content;
  }

  .third_bg_content {
    width: 90% !important;
    max-height: fit-content;
  }

  .third_bg_upper {
    min-height: fit-content;
    max-height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 8% auto;
  }

  .third_bg_upper div h1 {
    color: #434343 !important;
    text-align: center;
    font-family: ITC !important;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    text-transform: capitalize;
    margin-bottom: 15%;
  }

  .third_bg_upper div p {
    color: #434343 !important;
    text-align: center;
    font-family: ITC !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.132px;
    width: 90%;
  }

  .Ark_logo {
    width: 108px !important;
    height: 54px;
    padding: 1%;
    margin-bottom: 5%;
  }
  .Ark_logo img {
    width: 100%;
  }

  .mobius_logo_ {
    width: 108px !important;
    height: 54px;
    padding: 1%;
    margin-bottom: 5%;
  }

  .mobius_logo_ img {
    width: 100%;
  }

  .third_bg_lower_horizontal {
    min-height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    margin-top: 5%;
    flex-direction: column;
  }

  .third_bg_left_content {
    min-height: fit-content;
    width: 90%;
    margin-bottom: 5%;
  }

  .third_bg_lower_para1 p {
    /* border: 2px solid black; */

    color: #434343 !important;
    text-align: center;
    font-family: ITC !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.132px;
  }

  .third_bg_right_content {
    min-height: fit-content;
    width: 90%;
    margin-bottom: 5%;
  }

  .third_bg_lower_para2 p {
    color: #434343 !important;
    text-align: center;
    font-family: ITC !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.132px;
  }

  /* ----mobius won wrapper--- */
  .content_text p {
    color: #000;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: ITC !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 21px !important;
  }
}

@media screen and (max-width: 680px) and (min-width: 600px) {
  .Landing_top_bg {
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 90% !important;
    padding-top: 150px;
    /* padding-left: 50px; */
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 6.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100px;
    text-align: left;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 600px;
  }
  .bannerContentland {
    width: 100%;
    gap: 30px;
    text-align: left;
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    text-align: left;
  }
  /* ------------------------------------------ */
  /* button */

  .bannerContentBtnland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: white;
  }

  #getStartedBtnLanding {
    /* width: fit-content; */
    /* width: 500px; */
    width: 550px;
    /* box-sizing: border-box; */
    padding: 10px 30px 10px 30px;
    background: #ffa200;
    border: 1px solid #ffa200;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
  }

  #learnMoreBtnLanding {
    color: #fff !important;
    font-family: "ITC";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
  }

  .nexus_rightarrow img {
    color: white !important;
    fill: white;
    width: 20px;
  }

  /* -------------------------------- */

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 265px;
    max-height: fit-content;
  }

  .second_bg_content {
    width: 90% !important;
    min-height: fit-content;
    /* margin: 1% 0; */
    /* padding-left: 3%; */
  }

  /* --------------- */
  .second_bg_heading h1 {
    font-size: 2rem !important;
    line-height: 30.191px !important;
  }

  .second_bg_para1 p {
    width: 58%;
    /* font-size: 2.5rem; */
    line-height: 20.191px !important;
  }

  .second_bg_para2 p {
    width: 60%;
    font-size: 12px !important;
    line-height: 16px;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 120px;
    margin: 6% auto;
  }

  .content_nab-show {
    width: 220px;
  }

  .content_nab-show img {
    position: absolute;
    top: -35px;
    left: 10px;
    width: 150px;
    height: 180px;
  }

  .content_text p {
    width: 280px;
    font-family: ITC !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 22.764px !important;
  }

  .winner_Img {
    width: 100px;
    height: 60px;
  }

  .winner_Img img {
    width: 80% !important;
  }

  .android_swiper_heading h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
  .Landing_top_bg {
    min-height: 800px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 90% !important;
    /* padding-top: 120px; */
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 6rem;
    font-weight: 500;
    line-height: 100px;
    text-align: left;
    margin: 0;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 650px;
  }

  .bannerContentland {
    /* width: 70%; */
    width: 100%;
    gap: 40px;
    text-align: left;
    /* margin-top: 100px; */
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    text-align: left;
  }

  /* ------------------------------------------ */
  /* button */

  .bannerContentBtnland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: white;
  }

  #getStartedBtnLanding {
    /* width: fit-content; */
    width: 480px;
    /* box-sizing: border-box; */
    padding: 10px 30px 10px 30px;
    background: #ffa200;
    border: 1px solid #ffa200;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
  }

  #learnMoreBtnLanding {
    color: #fff !important;
    font-family: "ITC";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
  }

  .nexus_rightarrow img {
    color: white !important;
    fill: white;
    width: 20px;
  }

  /* -------------------------------- */

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 360px;
    max-height: fit-content;
    background: #000;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 90% !important;
    min-height: fit-content;
    margin: 10% 0;
    padding-left: 1%;
  }

  .second_bg_para1 p {
    width: 95%;
    padding: 5% 0% !important;
  }

  .second_bg_para2 p {
    width: 100%;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 140px;
    max-height: fit-content;
    margin-top: 30%;
  }

  .content_nab-show {
    width: 300px;
  }

  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 160px;
    width: 150px !important;
    height: 150px;
    padding: 1%;
  }

  .content_text p {
    margin: 0 auto;
    /* width: 400px !important; */
    /* width: 90% !important; */
    padding-top: 30px;
    text-align: left;
    padding-left: 90px;
  }

  .winner_Img {
    position: absolute !important;
    top: 10px !important;
    right: 5px;
    width: 100px !important;
    height: 50px !important;
  }

  .winner_Img img {
    width: 90% !important;
  }
  .android_swiper_heading h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 499px) and (min-width: 400px) {
  .Landing_top_bg {
    min-height: 800px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 90% !important;
    padding-top: 120px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 5rem;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    margin: 0;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* height: 1000px; */
  }

  .bannerContentland {
    width: 100%;
    gap: 40px;
    text-align: left;
    margin-top: 0px;
  }

  .bannerContentland > div > h1 {
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }

  .bannerContentland > div > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.36px;
    margin-bottom: 5%;
    text-align: left;
  }

  /* ------------------------------------------ */
  /* button */

  .bannerContentBtnland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: white;
  }

  #getStartedBtnLanding {
    /* width: fit-content; */
    width: 380px;
    /* box-sizing: border-box; */
    padding: 10px 30px 10px 30px;
    background: #ffa200;
    border: 1px solid #ffa200;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
  }

  #learnMoreBtnLanding {
    color: #fff !important;
    font-family: "ITC";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
  }

  .nexus_rightarrow img {
    color: white !important;
    fill: white;
    width: 20px;
  }

  /* -------------------------------- */

  .bannerContentBtnland {
    width: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 360px;
    max-height: fit-content;
    background: #000;
  }

  .mobile_view_img {
    display: block !important;
    padding-top: 70px;
  }

  .mobile_view_img img {
    width: 100%;
    height: 300px;
  }

  .second_bg_content {
    width: 90% !important;
    min-height: fit-content;
    margin: 10% 0;
    padding-left: 1%;
  }

  .second_bg_para1 p {
    width: 95%;
    padding: 5% 0% !important;
  }

  .second_bg_para2 p {
    width: 100%;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 120px;
    max-height: fit-content;
    margin-top: 30%;
  }

  .content_nab-show {
    width: 200px;
  }

  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 130px;
    width: 150px !important;
    height: 120px;
    padding: 1%;
  }

  .content_text p {
    /* margin: 0 auto; */
    padding-top: 30px;
    /* text-align: center !important; */
    text-align: left;
    padding-left: 50px;
  }

  .winner_Img {
    position: absolute !important;
    top: 10px !important;
    right: 5px;
    width: 90px !important;
    height: 50px !important;
  }

  .winner_Img img {
    width: 90% !important;
  }
  .android_swiper_heading h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 399px) and (min-width: 350px) {
  .Landing_top_bg {
    min-height: 800px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 90% !important;
    padding-top: 50px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 70px;
    text-align: left;
    margin: 0;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 550px;
  }

  .bannerContentland {
    width: 100%;
    gap: 20px;
    text-align: left;
    /* margin-top: 30px; */
  }

  .bannerContentland > div > h1 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }

  .bannerContentland > div > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
    text-align: left;
  }

  /* ------------------------------------------ */
  /* button */

  .bannerContentBtnland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: white;
  }

  #getStartedBtnLanding {
    /* width: fit-content; */
    width: 340px;
    /* box-sizing: border-box; */
    padding: 10px 30px 10px 30px;
    background: #ffa200;
    border: 1px solid #ffa200;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
  }

  #learnMoreBtnLanding {
    color: #fff !important;
    font-family: "ITC";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
  }

  .nexus_rightarrow img {
    color: white !important;
    fill: white;
    width: 20px;
  }

  /* -------------------------------- */

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 395px;
    max-height: fit-content;
    background: #000;
  }

  /* .mobile_view_img {
    display: block !important;
    padding-top: 70px;
  } */

  .mobile_view_img img {
    width: 100%;
    height: 300px;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 90% !important;
    min-height: fit-content;
    margin: 10% 0;
    padding-left: 1%;
  }

  .second_bg_para1 p {
    width: 95%;
    padding: 5% 0% !important;
  }

  .second_bg_para2 p {
    width: 100%;
  }

  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 120px;
    max-height: fit-content;
    margin-top: 30%;
  }

  .content_nab-show {
    width: 300px;
  }

  /* .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 100px;
    width: 150px !important;
    height: 140px;
    padding: 1%;
  } */

  .content_text p {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 350px !important;
    /* width: 90% !important; */
    padding-top: 30px;
  }

  .winner_Img {
    position: absolute !important;
    top: 10px !important;
    right: 5px;
    width: 80px !important;
    height: 50px !important;
  }

  .winner_Img img {
    width: 90% !important;
  }
  .android_swiper_heading h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 349px) and (min-width: 300px) {
  .Landing_top_bg {
    min-height: 800px;
    max-height: fit-content;
  }

  .bannerContentContainerland {
    width: 90% !important;
    padding-top: 100px;
    min-height: 900px;
    max-height: fit-content;
  }

  .bannerContentContainerland > h1 {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    margin: 0;
  }

  .bannerContentWrapper1 {
    width: 100%;
    margin-top: 0rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 700px;
  }

  .bannerContentland {
    width: 100%;
    gap: 40px;
    text-align: left;
    margin-top: 0px;
  }

  .bannerContentland > div > h1 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }

  .bannerContentland > div > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
    text-align: left;
  }

  /* ------------------------------------------ */
  /* button */

  .bannerContentBtnland {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    color: white;
  }

  #getStartedBtnLanding {
    /* width: fit-content; */
    width: 300px;
    /* box-sizing: border-box; */
    padding: 10px 30px 10px 30px;
    background: #ffa200;
    border: 1px solid #ffa200;
    border-radius: 10px;
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
  }

  #learnMoreBtnLanding {
    color: #fff !important;
    font-family: "ITC";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 20px;
  }

  .nexus_rightarrow img {
    color: white !important;
    fill: white;
    width: 20px;
  }

  /* ==========secong bg container============ */
  .second_bg_img-contaniner {
    min-height: 395px;
    max-height: fit-content;
    background: #000;
  }

  /* .mobile_view_img {
    display: block !important;
    padding-top: 70px;
  } */

  .mobile_view_img img {
    width: 100%;
    height: 300px;
  }

  .second_bg_content {
    /* width: 55%; */
    width: 90% !important;
    min-height: fit-content;
    margin: 10% 0;
    padding-left: 1%;
  }

  .second_bg_para1 p {
    width: 95%;
    padding: 5% 0% !important;
  }

  .second_bg_para2 p {
    width: 100%;
  }

  /* -------------------------------- */
}

@media screen and (max-width: 420px) and (min-width: 380px) {
  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 90px;
    width: 150px !important;
    height: 140px;
    padding: 1%;
  }
}
@media screen and (max-width: 380px) and (min-width: 350px) {
  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 90px;
    width: 150px !important;
    height: 140px;
    padding: 1%;
  }
}

@media screen and (max-width: 350px) and (min-width: 320px) {
  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 75px;
    width: 150px !important;
    height: 140px;
    padding: 1%;
  }
}

@media screen and (max-width: 320px) and (min-width: 100px) {
  /* -------------mobius_won_wrapper--------- */
  .mobius_won_content {
    width: 90%;
    min-height: 120px;
    max-height: fit-content;
    margin-top: 30%;
  }

  .content_nab-show {
    width: 300px;
  }

  .content_nab-show img {
    position: absolute;
    top: -100px;
    left: 70px;
    width: 140px !important;
    height: 130px;
    padding: 1%;
  }

  .content_text p {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 350px !important;
    /* width: 90% !important; */
    padding-top: 30px;
  }

  .winner_Img {
    position: absolute !important;
    top: 15px !important;
    right: 4px;
    width: 80px !important;
    height: 45px !important;
  }

  .winner_Img img {
    width: 85% !important;
  }
}

/* @media screen and (max-width: 600px) {
  #downArrow {
    margin-left: 30%;
  }
} */

.heading {
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "ITC";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 97.222% */
  background: linear-gradient(
    270deg,
    #ff9b00 6.66%,
    #f29d0c 22.64%,
    #ff9f00 38.62%,
    #d09a2e 48.6%,
    #0085ff 60.59%,
    #0085ff 86.57%,
    #0085ff 90.55%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subHeading {
  color: white;
  text-align: center;
  font-family: "ITC";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.48px;
}

/* @font-face {

  font-family: 'ITC';
  font-style: normal;
  font-weight: normal;
  src:  url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");

} */

.map {
  /* width: 1280px !important;
    height: 720px; */
  /* object-fit: cover; */
  /* overflow: hidden; */
  margin-left: auto;
  margin-right: auto;

  /* width: 100vw !important; */
  /* height: 100vh !important; */
  /* height: 100vh !important; */
  transition: 1s all ease-in-out;
  z-index: 10;
}
.mainDiv {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* height: 100vh;
     */
  /* max-height: fit-content; */

  /* 
    max-height: fit-content; */
  /* padding-top: 20px; */
  overflow: hidden;
  /* background-color: black; */
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainDiv33 {
  height: 120%;
  padding-bottom: 20px;
  /* height: 130vh !important; */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headDiv {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  height: 150px;
  transition: 1s all ease-in;
}
canvas {
  width: inherit !important ;
  height: inherit !important;
  object-fit: cover !important;
  aspect-ratio: auto 1920 / 1080 !important;
  z-index: 5;
  transition: 1s all ease-in;
  /* scale: 0.8; */
}
.mainDiv2 {
  position: absolute;
  left: 17% !important;
  top: 15px;
  transition: 1s all ease-in;
}
.map2 {
  width: 60% !important;
  height: 70% !important;
  /* margin-left: auto; */
  transition: 1s all ease-in-out;
  position: absolute;
  left: 6%;

  top: 13%;
}

.bottom {
  width: 45%;
  height: 30%;
  /* background-color: red; */
  position: absolute;
  left: 9.5%;
  bottom: 3%;
  transition: 1s all ease-in;
  transition-delay: 500ms;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 35%;
  height: 94%;
  /* background-color: red; */
  position: absolute;
  right: 60px;
  top: 8%;
  /* justify-content: center; */
  transition: 1s all ease-in;
  transition-delay: 500ms;
}

.bottomb {
  margin-left: 45%;
  margin-top: 20px;
  transform: translateX(-50%);
}

.rightr {
  position: absolute;
  right: -800px;
}

.bottombt {
  position: absolute;
  bottom: -400px;
}

.buttons {
  position: absolute;
  z-index: 500 !important;
  width: auto;
  height: auto;
  background-color: #ffa200;
  color: white;
  border: 0px solid black;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  transition: 0.2s background-color ease;
  right: auto;
  left: auto;
  bottom: 30px;
  padding: 10px 18px;
}

.buttons:hover {
  cursor: pointer;
  background-color: rgba(137, 43, 226, 1);
}

.splineVideo {
  /* width: 400px;
  height: 300px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.splineVideo video {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .right {
    right: 10px;
  }
  .map2 {
    width: 816px !important;
    height: 472px !important;
    top: 17% !important;
    left: 4% !important;
  }

  .map {
  }
}

@media only screen and (max-width: 980px) and (min-width: 300px) {
  .splineVideo {
    /* width: 200px;
    height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .splineVideo video {
    width: 100%;
    height: 100%;
  }

  .mainDiv {
    height: 80vh;
  }
}

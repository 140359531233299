/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.container {
  /* border: 2px solid green; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  margin: 5% 0 !important;
  /* border: 5px solid green; */
  /* width: 1200px; */
  width: 85%;
  display: flex !important;
}

.titleHeader {
  /* border: 2px solid red; */
  width: 18%;
  height: 448px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding-top: 0;
  margin-top: 0; */
}

.faqTitle > h1 {
  /* border: 2px solid red; */
  color: #4c4c4c;
  font-family: ITC;
  font-size: 41.813px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  margin: 0;
}

.accordionContent {
  /* border: 2px solid red; */
  /* height: 410px; */
  height: fit-content;
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 8%;
}

/* .singleAccordion  */
.accordionItem {
  /* background-color: yellow; */
  /* border: 2px solid blue; */

  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(143, 150, 161, 0.5);
  padding: 20px 0px 20px 0px;
  align-items: flex-start;
  align-self: stretch;
}

.AccordianHeader {
  /* border: 3px solid blue; */
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
  cursor: pointer;
}

.accordianHeaderTitle {
  /* border: 3px solid green; */
  color: #4c4c4c;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.toggleIcon {
  height: 20px;
  min-height: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

/* .AccordionItem  */
.accordionAnswer {
  /* border: 5px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-top: 1px solid #f9f9f9;
  color: #8f96a1;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: "ITC";

  /* max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out; */
}

.rotateIcon {
  transform: rotate(180deg);
}


@media only screen and (max-width: 2600px) and (min-width: 1801px) {
  .contentContainer {
    width: 1700px;
    /* width: 85%; */
    display: flex !important;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .contentContainer {
    /* width: 1420px; */
    width: 85%;
    display: flex !important;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .contentContainer {
    /* width: 1200px; */
    width: 85% !important;
    display: flex !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .contentContainer {
    /* width: 950px; */
    width: 90%;
    display: flex !important;
  }

  .titleHeader {
    width: 28%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .accordionContent {
    /* height: 410px; */
    height: fit-content;
    width: 72%;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6%;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .contentContainer {
    width: 90%;
  }

  .faqTitle > h1 {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 41.813px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px;
  }
}

@media screen and (max-width: 980px) and (min-width: 820px) {
  .titleHeader {
    width: 28%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .accordionContent {
    /* height: 410px; */
    height: fit-content;
    width: 72%;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6%;
  }
}

@media screen and (max-width: 820px) and (min-width: 720px) {
  .titleHeader {
    width: 32%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .accordionContent {
    height: fit-content;
    width: 68%;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
  }
}

@media screen and (max-width: 720px) and (min-width: 601px) {
  .titleHeader {
    width: 35%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .accordionContent {
    height: fit-content;
    width: 65%;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5%;
  }
}

@media screen and (max-width: 600px) and (min-width: 50px) {
  .container {
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentContainer {
    width: 90%;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 auto;
  }
  .titleHeader {
    width: 60%;
    height: 110px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .faqTitle > h1 {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 27.375px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.8px;
  }

  .accordionContent {
    /* border: 2px solid blue; */
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    padding-left: 0;
  }

  .singleAccordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgba(143, 150, 161, 0.5);
    padding: 12px 0px 13px 0px;
    align-items: flex-start;
    align-self: stretch;
  }

  .AccordianHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .accordianHeaderTitle {
    color: #4c4c4c;
    font-family: ITC;
    font-size: 13.625px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.4px;
    width: 100%;
    align-items: flex-start;
  }

  .dropdownImage {
    width: 20px;
    height: 20px;
  }
  .AccordionItem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-top: 1px solid #f9f9f9;
    color: #8f96a1;
    font-family: ITC;
    font-size: 13.625px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.4px;
    width: 90%;
  }
  /* .dropdownCondition {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s ease-in-out;
  }

  .dropdownCondition.active {
    max-height: 220px;
  } */
}

@media screen and (max-width: 460px) and (min-width: 370px) {
  .titleHeader {
    width: 75%;
    height: 110px;
  }
}

@media screen and (max-width: 370px) and (min-width: 100px) {
  .titleHeader {
    width: 75%;
    height: 110px;
  }
}

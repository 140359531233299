.infographicBlue {
  background: var(--Surface-Primary, #d8efff);
}

.newsletter {
  margin-bottom: 60px;
}

.dynamic {
  margin-top: 60px;
}

.textImage {
  margin-top: 60px;
}

.carousel {
  margin-top: 120px;
  margin-bottom: 0;
}

/* .poppedup {
  margin-top: 100px;
  margin-bottom: 100px;
} */

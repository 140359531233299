/* @font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */
.mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4% 0;
}

.wrapper {
  width: 1700px;
  text-align: center;
  min-height: 90px;
  max-height: fit-content;
  /* border: 2px solid red; */
}

.titleWrapper {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  /* border: 2px solid blue; */

  display: flex;
  padding: 0px 122.405px 0px 122.595px;
  align-items: center;
  margin: 2% 0;

}

.title > h1 {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 41.063px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
}

.card_para > p {
  font-family: ITC;
  color: #4c4c4c;
  text-align: center;
  font-size: 17.875px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.3px;
  letter-spacing: 3px;
}

.cardContainer {
  /* border: 1px solid green; */

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  padding: 10px 0px 30px 0px;
}

.card {
  width: 24%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 35px 0px;
  border-radius: 10px;
  background: var(--swissborg-com-nero-02, rgba(255, 255, 255, 0));
  box-shadow: 0px 3px 8px 0px rgba(25, 30, 41, 0.1);
}

/* ----for desktop view---------- */
@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .wrapper {
    margin: 0 auto;
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .wrapper {
    margin: 0 auto;
    width: 950px;
  }
}

/* ------for tablet  view------------- */
@media only screen and (max-width: 980px) and (min-width: 601px) {
  .title {
    padding: 0px 50px 0px 50px !important;
  }
}

/* ------for mobile view------------- */
@media only screen and (max-width: 600px) and (min-width: 50px) {
  .wrapper {
    width: 90%;
    text-align: center;
    min-height: 90px;
    max-height: fit-content;
  }

  .titleWrapper {
    width: 100%;

    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  .title {
    padding: 0px 10px 0px 10px !important;
  }

  .title > h1 {
    width: 100%;

    color: #4c4c4c;
    text-align: center;
    font-family: ITC;
    font-size: 23.713px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 31.589px;
  }

  .cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;

    padding: 10px 0px 30px 0px;
    flex-direction: column;
  }

  .card {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px 0px;

    /* border-radius: 10px;
        background: var(--swissborg-com-nero-02, rgba(255, 255, 255, 0));
        box-shadow: 0px 3px 8px 0px rgba(25, 30, 41, 0.1); */
  }
}

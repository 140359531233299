.sectionmain {
  width: 100%;
  display: flex;
  justify-content: center;
}
.article {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 5%;
  width: 1700px;
  height: fit-content;
}
.maincontent {
  /* border: 2px solid red; */
  width: 50%;
  height: fit-content;
  padding-top: 50px;
  padding-bottom: 50px;
}
.buildhead {
  font-weight: 500;
  font-size: 68px;
  line-height: 80px;
  height: fit-content;
  letter-spacing: 0.02em;
  width: 100%;
  color: #171717;
}

.Joinus {
  color: #007cd7 !important;
  font-family: DM Sans;
  font-size: 64px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  /* letter-spacing: -1.92px; */
  width: 100%;
  /* background-color: red; */
}
.content {
  color: #494949;
  font-family: DM Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 80%;
}

.openingsbutton {
  margin-top: 30px;
  background-color: #ffa200;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
  letter-spacing: 0.02em;
  cursor: pointer;
  height: fit-content;
}

@media only screen and (max-width: 2560px) and (min-width: 601px) {
  .buildhead {
    color: #171717;
    font-family: DM Sans;
    font-size: 68px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    /* letter-spacing: -2.04px; */
  }

  .Joinus {
    color: #ad00a2;
    font-family: DM Sans;
    font-size: 64px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    /* letter-spacing: -1.92px; */
  }

  .content {
    color: #494949;
    font-family: DM Sans;
    font-size: 21px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 30px !important;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .article {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 5%;
    width: 1420px;
    height: fit-content;
  }
  .maincontent {
    width: 70%;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .article {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 5%;
    width: 1200px;
    height: fit-content;
  }
  .maincontent {
    width: 70%;
  }
  .buildhead {
    font-size: 60px;
    line-height: 80px;
  }

  .Joinus {
    font-size: 55px;
    line-height: 80px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .article {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 5%;
    width: 950px;
    height: fit-content;
  }
  .maincontent {
    width: 70%;
  }
  .buildhead {
    font-size: 60px;
    line-height: 80px;
  }
  .Joinus {
    font-size: 55px;
    line-height: 80px;
  }
}
@media only screen and (max-width: 980px) and (min-width: 600px) {
  .article {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 0%;
    width: 90%;
    height: fit-content;
  }
  .maincontent {
    width: 100%;
    padding-left: 4%;
  }
  .buildhead {
    font-size: 60px;
    line-height: 80px;
  }
  .Joinus {
    font-size: 55px;
    line-height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .article {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 0%;
    width: 90%;
    height: fit-content;
  }
  .maincontent {
    width: 100%;
    padding-left: 4%;
  }
  .buildhead {
    color: #171717;
    font-family: DM Sans;
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Joinus {
    /* font-size: 40px;
    line-height: 50px; */
    color: #ad00a2;
    font-family: DM Sans;
    font-size: 54px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content {
    /* color: #494949;
    font-size: 18px;
    line-height: 30px;
    height: fit-content;
    width: 90%;
    text-align: justify;
    font-weight: 400; */
    color: #494949;
    font-family: DM Sans;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-align: justify;
    height: fit-content;
    width: 90%;
  }

  .openingsbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    /* background-color: #ad00a2; */
    padding: 10px 20px;
    border-radius: 10px;
    width: 90%;
    height: 48px !important;
    letter-spacing: 0.02em;
    cursor: pointer;
    height: fit-content;

    color: var(--Color, #fff);
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;
  }
}

.maindropdown {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  background-color: #ffff;
  /* background-color: #FFA200; */
}
.maindropdown > div {
  width: 90%;
  height: fit-content;
  /* overflow-y: scroll; */
}

.firstpage {
  min-height: 80vh;
  max-height: fit-content;
  /* height: 650px; */
  width: 100%;
  background-color: #ffff;
}
.menu {
  min-height: 650px;
  max-height: fit-content;
  z-index: 999;
  background-color: #ffff;
  border-radius: 0px 0px 10px 10px;
}

#contents {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  max-height: fit-content;
  position: relative;
}

.hrline {
  position: relative;
  height: 5vh;
  padding: 0px 10px 0px 10px;
}

#bottomcontent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  min-height: 25vh;
  max-height: fit-content;
  text-align: center;
}

#book {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
  /* height: 6vh; */
  background: #FFA200;
  border-radius: 10px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
}

#login {
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  line-height: 30px;
  height: 50px;
  width: 80%;
  border: 2px solid #FFA200;
  border-radius: 10px;
  color: #FFA200;
}

.listitems {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  /* padding-bottom: 4px; */
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
  /* width: 80vw; */
  border-radius: 8px;
  /* margin-left: 1rem !important; */
}
#contents{
  /* margin-top: 30px; */
  padding-top: 50px !important; 
  /* background-color: red; */
}
.content {
  font-weight: 400;
  /* font-size: 1.5rem; */
  font-family: "DM Sans";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 25px;
  line-height: 30px;
  padding-top: 2px;
  width: 90%;
  padding-bottom: 5px;
  /* width: 80% !important; */
  /* background-color: #FFA200; */
  padding-left: 1rem;
}
.rightarrow {
  /* margin-left: 50%; */
  /* padding-left: rem; */
  padding-top: 2px;
  padding-right: 1rem;
  /* right:0; */
  /* flex: 1; */
}

.listitems:hover {
  background:#E5F2FB;
}
.listitems > div:nth-child(2) {
  visibility: visible;
}
.listitems:hover > div:nth-child(2) {
  visibility: visible;
}

.imagecontainer {
  padding-left: 25px;
  padding-top: 30px;
}
.imagecontainer img {
  width: 510px;
  height: 298px;
  border-radius: 20px;
}
.header {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  text-align: start;
  padding-left: 25px;
  color: #1a1a1a;
  height: fit-content;
  margin-bottom: 10px;
}
.description {
  padding-left: 25px;
  color: #1a1a1a;
}
.arrowfolder {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 5px 25px;
  height: 40px;
}
.arrow {
  padding: 4px 0px 0px 20px;
}

#login img {
  width: 1rem;
  /* margin-left: 2rem;  */
  /* height: 12.05px; */
}
#btns {
  padding-right: 5rem;
}
.returnarrow {
  /* display: flex;
    flex-direction: row;
    justify-content: right; */
  /* alig-items: flex-end; */
  margin-left: 0vw;
  padding-top: 3vw;
  padding-bottom: 1vw;
  width: 25px;
}
.leftarrowStyle{
  width: 30px;
  height: 30px;
  /* height: 100%; */
  /* background-color: red; */
}
@media only screen and (min-width: 980px) {
  /* .menu{
        display: none;
    }
    .firstpage{
        display: none;
    } */
  .maindropdown {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  #contents {
    padding-top: 20px;
    height: 40vh;
    overflow-y: scroll;
  }
  .content {
    font-size: 24px;
    line-height: 30px;
  }
  .listitems {
    height: fit-content;
  }

  .listitems:hover {
    background:#E5F2FB;
  }
  .listitems > div:nth-child(2) {
    visibility: visible;
  }
  .listitems:hover > div:nth-child(2) {
    visibility: visible;
  }
  #book, #login {
    width: 70%;
    height: 50px;
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 320px) {
  #contents {
    padding-top: 20px;
    height: 40vh;
  }
  .content {
    font-size: 18px;
    /* line-height: 24px; */
  }
  .listitems {
    height: fit-content;
  }

  .listitems:hover {
    background:#E5F2FB;
  }
  .listitems > div:nth-child(2) {
    visibility: visible;
  }
  .listitems:hover > div:nth-child(2) {
    visibility: visible;
  }
  .maindropdown > div {
    /* overflow-y: scroll; */
    height: 80vh;
  }
  .hrline {
    height: 1vh;
  }
  #book, #login {
    width: 70%;
    height: 30px;
    font-size: 16px;
    line-height: 20px;
  }
  .menu{
    height: fit-content;
    min-height: fit-content;
  }
}



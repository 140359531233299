.mainDiv{
    width: 440px;
    height: 146px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;
    border-radius: 10px; 
    padding-left: 20px;
    transition: 0.2s all ease-in-out;
    backdrop-filter: blur(102px); 

}
@font-face {

    font-family: 'ITC';
    font-style: normal;
    font-weight: normal;
    src:  url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
      format("truetype");
  
  }
  
.firstText{
    color: #F4F4F4;
font-family: ITC;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.secondText{
    color: #F4F4F4;
font-family: ITC;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
}

.thirdText{
    color: #F4F4F4;
font-family: ITC;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 14.655px; /* 146.552% */
}

.fourthText{
    color: #F4F4F4;
font-family: ITC;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.subDiv{
    display: flex;
    flex-direction: column;
    /* gap: 1px; */
    height: 100px;
    justify-content: space-between;
    /* background-color: red; */
    padding-right: 10px;
}

.down{
    display: flex;
    gap: 2px;
    align-items: center;
}


.mainDiv:hover{
    scale: 1.1;
    transition: 0.2s all ease-in-out;
    border: 4px solid #0C90DE; 

}
.mainDiv  .down img{
    transition: 0.4s all ease-in;

}


.mainDiv:hover  .down img{
        transform: rotate(90deg) !important;
        transition: 0.4s all ease-in;
    
}


@media screen and (max-width:1280px) {
    .mainDiv{
        width: 360px;
    }
}
.navlink {
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  text-align: left;
}

.navlink:hover {
  --tw-text-opacity: 1;
  color: rgb(45 34 170 / var(--tw-text-opacity));
}
.cards1 {
  height: auto;
  margin-top: 10px;
  list-style: none;
}
.bottomfolder {
  width: 100%;
  padding-top: 50px;
  text-align: center;
}
.topcont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
}
.header {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 35px;
  color: #f38e37;
  font-family: DM Sans;
}
.footermain1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footermain {
  border-top: 2px solid #d9d9d9;
  width: 1700px;
  padding-top: 40px;
  padding-bottom: 5vh;
}
.listelements {
  margin-bottom: 8px;
}
.bottomcontent {
  margin-top: 11px;
  display: flex;

  justify-content: center;
}
.routingcontent {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.Termscontent > a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
ul {
  padding-left: 0rem !important;
  list-style: none;
}

.bottomfolder ul {
  display: flex;
  justify-content: center;
  gap: 3%;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .footermain {
    border-top: 2px solid #d9d9d9;
    width: 1420px;
    padding-top: 40px;
    padding-bottom: 5vh;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .footermain {
    border-top: 2px solid #d9d9d9;
    width: 1200px;
    padding-top: 40px;
    padding-bottom: 5vh;
  }
}
@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .navlink {
    font-size: 15px;
  }
  .footermain {
    border-top: 2px solid #d9d9d9;
    width: 950px;
    padding-top: 40px;
    padding-bottom: 5vh;
  }
}
@media screen and (max-width: 980px) {
  .navlink {
    font-size: 15px;
  }
  .footermain {
    border-top: 2px solid #d9d9d9;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 5vh;
  }
  .topcont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
    gap: 0rem;
    height: fit-content;
    width: 90vw;
  }
  .header {
    margin-left: 0rem !important;
    padding-left: 0rem !important;
    font-weight: 500;
  }
}
@media screen and (max-width: 620px) {
  .navlink {
    font-size: 15px;
  }
  .bottomcontent {
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }
  .bottomcontent > div:last-child {
    text-align: center;
  }
  .cards1 {
    height: auto;
    margin-top: 0.3em;
  }
  .listelements {
    margin-bottom: 3px;
  }
}

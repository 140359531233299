
    
    .btn:hover {
        --tw-bg-opacity: 1;
        color: white;
        background: #007CD7;
        color: white !important; 

      }
    
    .btn_filled {
        /* font-family: "DM Sans"; */
         font-weight: 500;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px 30px;
        font-size: 20px;
        width: fit-content;
        height: fit-content;
        background: #007CD7;
        border: 1px solid #007CD7;
        border-radius: 10px;
        color: white !important; 
    }
    
    .btn{
    
        box-sizing: border-box;
    z-index: 100;
    font-weight: 500;
    font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:10px 30px;
        width: fit-content;
        height: fit-content;
        color: #007CD7 ;
        border: 2px solid #007CD7;
        border-radius: 10px;
        text-decoration: none !important;
        cursor: pointer;
    }
    @media only screen and (max-width:420px){
  
        .btn{
    
            box-sizing: border-box;
        z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            /* color: #AD00A2 ;
            border: 2px solid #AD00A2; */
            border-radius: 10px;
            text-decoration: none !important;
            cursor: pointer;
        }
        .btn_filled {
    
    
            box-sizing: border-box;
        
            /* Auto layout */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            /* background: #AD00A2;
            border: 1px solid #AD00A2; */
            border-radius: 10px;
            color: white !important; 
        }


    }

    /* @media only screen and (max-width:768px) and(min-width:420px){
  
        .btn{
    
            box-sizing: border-box;
        z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 5px;
            width: 15rem;
            height: 3rem;
         
            color: #AD00A2 ;
            border: 2px solid #AD00A2;
            border-radius: 10px;
            text-decoration: none !important;
            cursor: pointer;
        }
        .btn_filled {
    
    
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 5px;
       
            width: 15rem;
            height: 3rem;
        
            background: #AD00A2;
            border: 1px solid #AD00A2;
            border-radius: 10px;
            color: white !important; 
        }


    }

    @media only screen and (max-width:1024px) and(min-width:768px){
  
        .btn{
    
            box-sizing: border-box;
        z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 5px;
            width: 20rem;
            height: 3rem;
         
            color: #AD00A2 ;
            border: 2px solid #AD00A2;
            border-radius: 10px;
            text-decoration: none !important;
            cursor: pointer;
        }
        .btn_filled {
    
    
            box-sizing: border-box;
        
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 5px;
            width: 20rem;
            height: 3rem;
           
        
            background: #AD00A2;
            border: 1px solid #AD00A2;
            border-radius: 10px;
            color: white !important; 
        }


    } */
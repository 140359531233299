/* @font-face {
  font-family: ITC;
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
} */

.invest {
  display: flex;
  padding: 47.705px 51px 45.705px 51px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--Surface-Secondary, #fff7ea);
}

.investContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.investContentCont {
  width: 1700px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.investNumber {
  display: flex;
  align-items: center;
  max-height: 55.2px;
  color: var(--Yellow, #ffa200);
  text-align: center;
  font-family: ITC;
  font-size: 36.938px;
  font-style: normal;
  font-weight: 500;
  line-height: 55.2px;
  margin: 0;
}
.investName {
  width: 150px;
  color: #4c4c4c;
  text-align: center;
  font-family: ITC;
  font-size: 14.766px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.7px;
  margin: 0;
}


.amountAndSymbol{
  display: flex;

}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .investContentCont {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .investContentCont {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .investContentCont {
    width: 950px;
  }
}

@media screen and (max-width: 980px) and (min-width: 601px) {
  .invest {
    display: flex;
    padding: 45px 32px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .investContainer {
    width: 100%;
  }

  .investContentCont {
    width: 90%;
  }
  .investNumber {
    max-height: 55.2px;
    color: var(--Yellow, #ffa200);
    text-align: center;
    font-family: ITC;
    font-size: 36.938px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px; /* 149.442% */
    margin: 0;
  }
  .investName {
    max-width: 150px;
    color: #4c4c4c;
    text-align: center;
    font-family: ITC;
    font-size: 14.76px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .invest {
    display: flex;
    padding: 19.32px 70px 35.91px 70px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background: var(--Surface-Secondary, #fff7ea);
  }
  .investContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
  }
  .investContentCont {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .investNumber {
    max-height: 55.2px;
    color: var(--Yellow, #ffa200);
    text-align: center;
    font-family: ITC;
    font-size: 36.938px;
    font-style: normal;
    font-weight: 500;
    line-height: 55.2px; /* 149.442% */
    margin: 0;
  }
  .investName {
    max-width: 150px;
    color: #4c4c4c;
    text-align: center;
    font-family: ITC;
    font-size: 14.766px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.7px; /* 140.19% */
    margin: 0;
  }
}


.blogswipermain{
    width: 100%;
}
  
.swiper-slide {
    /* margin-left: 5px; */
    padding: 0;
  }

.Blogcard1{
    width: 95%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px;
    border-radius: 20px; 
}
.img1{
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FBlogs_CareerPage%2FBlog1img2.webp?alt=media&token=3bedec10-6ae4-4ed2-b06e-5a9b637fb151");
    background-repeat: no-repeat;
    background-size: cover;
}
.img2{
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FBlogs_CareerPage%2FBlog2img1.webp?alt=media&token=794a1aa2-f114-4387-9cb4-61db4f863808");
    background-repeat: no-repeat;
    background-size: cover;
}
.img3{
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FBlogs_CareerPage%2FBlog3img3.webp?alt=media&token=d68099e0-4e78-40b9-b9c0-2428c90c45bd");
    background-repeat: no-repeat;
    background-size: cover;
}
.img4{
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FBlogs_CareerPage%2FBlog4img1.webp?alt=media&token=2ca06f4b-2c8e-4eff-928f-a562dfd1d51c");
    background-repeat: no-repeat;
    background-size: cover;
}
.img5{
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7)), url("https://firebasestorage.googleapis.com/v0/b/nexusconnects-bfe18.appspot.com/o/aidtaasImages%2Fimagesnew%2FBlogs_CareerPage%2FBlog5img2.webp?alt=media&token=c4038c27-ff38-4375-8a79-9f0298609c57");
    background-repeat: no-repeat;
    background-size: cover;
}


.Blogcontent1{
    /* color: #007ad1; */
    color: #FFFFFF;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    width: 100%;
    height:fit-content;
}

@media only screen and (max-width:1800px) and (min-width:1500px) {
    .Blogcard1{
        width: 95%;
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 20px;
        border-radius: 20px; 

    }
   
.Blogcontent1{
    /* color: #007ad1; */
    color: #FFFFFF;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    width: 100%;
    height:fit-content;
}
.swiper-slide {
    /* padding-right: 5px; */
    margin-left: 5px;
    /* padding: 0; */
  }

}

@media only screen and (max-width:1500px) and (min-width:1250px) {
    .Blogcard1{
        width: 92%;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 20px;
        border-radius: 20px; 

    }
   
.Blogcontent1{
    /* color: #007ad1; */
    color: #FFFFFF;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    width: 100%;
    height:fit-content;
}
/* .swiper-slide { */
    /* padding-right: 5px; */
    /* margin-left: 5px; */
    /* padding: 0; */
  /* } */

}
@media only screen and (max-width:1250px) and (min-width:980px) {
    .Blogcard1{
        width: 95%;
        height: 430px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 20px;
        border-radius: 20px; 

    }
   
.Blogcontent1{
    /* color: #007ad1; */
    color: #FFFFFF;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    width: 100%;
    height:fit-content;
}
}
@media  screen and (max-width:980px){
    .Blogcard1{
        width: 95%;
        height: 400px;
    }

}
@media  screen and (max-width:600px){
    .Blogcard1{
        width: 95%;
        height: 300px;
    }
    /* .swiper-slide {
        padding-right: 5px;
        margin-left: 5px;
      } */
      .Blogcontent1{
        color: #FFFFFF;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        width: 100%;
        height:fit-content;
    }
}

@media screen and (max-width:420px){
    .Blogcard1{
        width: 95%;
        height: 270px;
    }
}





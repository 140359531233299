@font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.contentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mainCont {
  width: 1700px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* border: 1px solid black; */
  margin-top: 50px;
  margin-bottom: 100px;
}

.imgCont {
  width: 50%;
}

.modalContainer {
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  width: 520px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}

.modalContainer > h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* width: 100%; */
  text-align: left;
  color: #4c4c4c;
}
label {
  color: #474747;
  font-size: 14px;
}

#phinpt {
  color: #474747;
  font-size: 14px;
  /*   height: inherit; */
  border: none;
}

.policy {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.policy > input {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #4c4c4c;
  background: var(--Color, #fff);
}

.policy > span {
  color: #4c4c4c;
  font-family: ITC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.svgg {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
  float: right !important;

  position: absolute;
  top: 10px;
  right: 10px;
}

.svgg:hover {
  cursor: pointer;
}

.h11 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modalFormStyle {
  height: 450px;
  top: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputField {
  height: 40px;
  width: 100% !important;
  background-color: #e1e1e1;
  opacity: 65%;
  border-radius: 10px;
  color: #000;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  background: var(--Color, #fff);
  padding: 5px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.inputField:focus {
  color: black;
}

#txtArea {
  height: 100px;
}

.form {
  width: -webkit-fill-available;
}

.first {
  display: flex;
  gap: 25px;
  width: 100%;
}

.submitButton {
  height: auto;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: #007cd7 !important;
  border-radius: 10px;
  padding: 10px 30px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
}

.h111 {
  color: black !important;
}

.submitButton:hover {
  background-color: #45a049;
}

.tTemp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: auto;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
}

.tTemp > h1 {
  font-size: 32px;
}

.tTemp > p {
  font-size: 16px;
  font-weight: 500;
}

.tTemp > button {
  padding: 8px 16px;
  border: none;
  background-color: #007cd7;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  margin-top: 20px;
}

.error {
  color: red;
  font-size: 14px;
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .mainCont {
    width: 1420px;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .mainCont {
    width: 1200px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .mainCont {
    width: 950px;
    justify-content: space-between;
  }
  .imgCont {
    width: 40%;
  }
  .imgCont > img {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .mainCont {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .imgCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
  }
  .modalContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalContainer > form {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Color, #fff);
    width: 600px;
    padding: 20px;
  }
  .tTemp {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
}

@media screen and (max-width: 600px) {
  .mainCont {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .imgCont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgCont > img {
    width: 90%;
  }
  .modalContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalContainer > form {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Color, #fff);
    width: 90%;
    padding: 12px;
  }
  .tTemp > h1 {
    color: #000 !important;
  }
  .error {
    color: red;
    font-size: 12px;
  }
}


.map__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    108.56deg,
    #bfeeff 0%,
    #ffe6f7 56.93%,
    #ffe6e6 100%
  );
}

.bg1 {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    200px 200px at 95% 5%,
    rgba(0, 93, 164, 0.4) 0%,
    rgba(0, 93, 164, 0) 100%
  );
}

.bg2 {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    200px 300px at 2% 50%,
    hsl(36, 100%, 50%, 0.4) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}

.contentContainer {
  width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 132px;
}

.contentContainer > .topcont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentContainer > .topcont > img {
  width: 867px;
  height: 648px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.contentContainer > .topcont > .btmbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.barctn1 ul,
.barctn2 ul {
  list-style: none;
}

.barctn1 ul li::before,
.barctn2 ul li::before {
  content: "•";
  display: inline-block;
  width: 0.5em;
  margin-left: -0.5em;
  line-height: 1;
  vertical-align: -0.2em;
}

.barctn1 ul li::before {
  color: #007ad1;
  font-size: 40px;
}

.barctn2 ul li::before {
  color: #f38e37;
  font-size: 40px;
}

.bottomcont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  gap: 122px;
}

.bottomcont > .item {
  width: 262px;
  height: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: url(./Container.png);
}

.bottomcont > .item > h1 {
  color: #474747;
  font-family: ITC Avant Garde Gothic;
  font-size: 62px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}

.bottomcont > .item > h5 {
  color: #474747;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: ITC Avant Garde Gothic;
  font-size: 20.1px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.8px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}


.map__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    108.56deg,
    #bfeeff 0%,
    #ffe6f7 56.93%,
    #ffe6e6 100%
  );
}

.bg1 {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    200px 200px at 95% 5%,
    rgba(0, 93, 164, 0.4) 0%,
    rgba(0, 93, 164, 0) 100%
  );
}

.bg2 {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    200px 300px at 2% 50%,
    hsl(36, 100%, 50%, 0.4) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}

.contentContainer {
  width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 132px;
}

.contentContainer > .topcont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.contentContainer > .topcont > img {
  width: 867px;
  height: 648px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.contentContainer > .topcont > .btmbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 64px;
}

.barctn1 ul,
.barctn2 ul {
  list-style: none;
  font-size: 12px;
}

.barctn1 ul li::before,
.barctn2 ul li::before {
  content: "•";
  display: inline-block;
  width: 0.5em;
  margin-left: -0.5em;
  line-height: 1;
  vertical-align: -8px;
}

.barctn1 ul li::before {
  color: #007ad1;
  font-size: 40px;
}

.barctn2 ul li::before {
  color: #f38e37;
  font-size: 40px;
}

.bottomcont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  gap: 122px;
}

.bottomcont > .mbdiv1 {
  display: flex;
  gap: 122px;
}

.bottomcont > .mbdiv1 > .item {
  width: 262px;
  height: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: url(./Container.png);
  /* border: 1px solid black; */
}

.bottomcont > .mbdiv2 > .item {
  width: 262px;
  height: 124px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: url(./Container.png);
  /* border: 1px solid black; */
}

.bottomcont > .mbdiv1 > .item > h1 {
  color: #474747;
  font-family: ITC Avant Garde Gothic;
  font-size: 62px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}

.bottomcont > .mbdiv2 > .item > h1 {
  color: #474747;
  font-family: ITC Avant Garde Gothic;
  font-size: 62px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}

.bottomcont > .mbdiv1 > .item > h5 {
  color: #474747;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: ITC Avant Garde Gothic;
  font-size: 20.1px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.8px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}

.bottomcont > .mbdiv2 > .item > h5 {
  color: #474747;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: ITC Avant Garde Gothic;
  font-size: 20.1px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.8px;
  margin: 0;
  text-align: left;
  margin-left: 31px;
}

@media only screen and (min-width: 600px) and (max-width: 800px) {
  .contentContainer{
    width: 90%;
  }
  .contentContainer > .topcont {
    width: 100%;
  }

  .contentContainer > .topcont > img {
    width: 100%;
    height: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  } 

  .bottomcont{
    gap: 80px;
  }

  .bottomcont>.mbdiv1{
    gap: 80px;
  }

  .bottomcont>.mbdiv1>.item,
  .bottomcont>.mbdiv2>.item{
    width: 150px;
    height: 100px;
    background-size: 100% 100%;
  }

  .bottomcont > .mbdiv1 > .item > h1,
  .bottomcont > .mbdiv2 > .item > h1{
    font-size: 40px;
    line-height: 60px;
  }

}

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .contentContainer{
    width: 90%;
  }
  .contentContainer > .topcont {
    width: 100%;
  }

  .contentContainer > .topcont > img {
    width: 100%;
    height: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  } 

  .bottomcont{
    gap: 100px;
  }

  .bottomcont>.mbdiv1{
    gap: 100px;
  }

  .bottomcont>.mbdiv1>.item,
  .bottomcont>.mbdiv2>.item{
    width: 180px;
    height: 100px;
    background-size: 100% 100%;
  }

  .bottomcont > .mbdiv1 > .item > h1,
  .bottomcont > .mbdiv2 > .item > h1{
    font-size: 40px;
    line-height: 60px;
  }
}


@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .contentContainer{
    width: 90%;
  }
  .contentContainer > .topcont {
    width: 100%;
  }

  .contentContainer > .topcont > img {
    width: 100%;
    height: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  } 

  .bottomcont{
    gap: 120px;
  }

  .bottomcont>.mbdiv1{
    gap: 120px;
  }

  .bottomcont>.mbdiv1>.item,
  .bottomcont>.mbdiv2>.item{
    width: 200px;
    height: 120px;
    background-size: 100% 100%;
  }

  .bottomcont > .mbdiv1 > .item > h1,
  .bottomcont > .mbdiv2 > .item > h1{
    font-size: 40px;
    line-height: 60px;
  }
}


@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
    /* border: 1px solid black; */
    padding-bottom: 70px;
  }

    .contentContainer > .topcont {
    width: 100%;
  }

  .contentContainer > .topcont > img {
    width: 100%;
    height: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  } 

  
  .bottomcont {
    flex-direction: column;
    gap: 50px;
  }
  .bottomcont > .mbdiv1 {
    gap: 40px;
  }
  .bottomcont > .mbdiv1 > .item {
    width: 155px;
    height: 77px;
    background: url(./Container.png);
    background-size: 100% 100%;
  }
  .bottomcont > .mbdiv2 > .item {
    width: 155px;
    height: 77px;
    background: url(./Container.png);
    background-size: 100% 100%;
  }
  .bottomcont > .mbdiv1 > .item > h1 {
    font-size: 40px;
    line-height: normal;
  }
  .bottomcont > .mbdiv2 > .item > h1 {
    font-size: 40px;
    line-height: normal;
  }
  .bottomcont > .mbdiv1 > .item > h5 {
    font-size: 14px;
    line-height: normal;
  }
  .bottomcont > .mbdiv2 > .item > h5 {
    font-size: 14px;
    line-height: normal;
  }
  .contentContainer > .topcont > .btmbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .barctn1 ul,
  .barctn2 ul {
    list-style: none;
    font-size: 8px;
  }
  .barctn1 ul li::before {
    color: #007ad1;
    font-size: 30px;
  }

  .barctn2 ul li::before {
    color: #f38e37;
    font-size: 30px;
  }
  .barctn1 ul li::before,
  .barctn2 ul li::before {
    content: "•";
    display: inline-block;
    width: 0.5em;
    margin-left: -0.5em;
    line-height: 1;
    vertical-align: -6px;
  }
}


@media screen and (max-width: 600px) {
  .contentContainer {
    width: 90%;
    /* border: 1px solid black; */
    padding-bottom: 70px;
  }

    .contentContainer > .topcont {
    width: 100%;
  }

  .contentContainer > .topcont > img {
    width: 100%;
    height: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  } 

  
  .bottomcont {
    flex-direction: column;
    gap: 50px;
  }
  .bottomcont > .mbdiv1 {
    gap: 40px;
  }
  .bottomcont > .mbdiv1 > .item {
    width: 120px;
    height: 60px;
    background: url(./Container.png);
    background-size: 100% 100%;
  }
  .bottomcont > .mbdiv2 > .item {
    width: 120px;
    height: 60px;
    background: url(./Container.png);
    background-size: 100% 100%;
  }
  .bottomcont > .mbdiv1 > .item > h1 {
    font-size: 32px;
    line-height: normal;
  }
  .bottomcont > .mbdiv2 > .item > h1 {
    font-size: 32px;
    line-height: normal;
  }
  .bottomcont > .mbdiv1 > .item > h5 {
    font-size: 13px;
    line-height: normal;
  }
  .bottomcont > .mbdiv2 > .item > h5 {
    font-size: 13px;
    line-height: normal;
  }
  .contentContainer > .topcont > .btmbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .barctn1 ul,
  .barctn2 ul {
    list-style: none;
    font-size: 8px;
  }
  .barctn1 ul li::before {
    color: #007ad1;
    font-size: 30px;
  }

  .barctn2 ul li::before {
    color: #f38e37;
    font-size: 30px;
  }
  .barctn1 ul li::before,
  .barctn2 ul li::before {
    content: "•";
    display: inline-block;
    width: 0.5em;
    margin-left: -0.5em;
    line-height: 1;
    vertical-align: -6px;
  }
}
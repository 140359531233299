@font-face {
  font-family: "ITC";
  src: url("../../../public/fonts/ITC\ Avant\ Garde\ Gothic\ Medium.otf")
    format("truetype");
}

.container {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.wrapper {

  /* border: 2px solid green; */
  width: 1700px !important;
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3% 0;
  flex-wrap: wrap;
  gap: 2%;
  column-gap: 2%;
  row-gap: 2%;
}

.bottomContent {
  display: flex;
  align-items: center;
  height: fit-content;

  border-radius: 8px;
  background: var(--Color, #fff);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 10px 3px;
  height: 140px;
  width: 30%;
  margin-bottom: 2.5%;

}

.logo {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
}

.logo img {
  width: 90%;
  border-radius: 50%;
}

.name_designation {
  padding-left: 2%;
  width: 100%;
}

.name_designation h1 {
  color: #4C4C4C;
  font-family: ITC;
  font-size: 18.906px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.23px;
}

.name_designation p {
  color: #4C4C4C;
  font-family: ITC;
  font-size: 15.25px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  min-height: 60px;
  /* border: 2px solid green; */
}

@media only screen and (max-width: 1800px) and (min-width: 1500px) {
  .wrapper {
    margin: 0 auto;
    width: 1420px !important;

  }
}

@media only screen and (max-width: 1500px) and (min-width: 1250px) {
  .wrapper {
    margin: 0 auto;
    width: 1200px !important;

  }
}

@media only screen and (max-width: 1250px) and (min-width: 980px) {
  .wrapper {
    margin: 0 auto;
    width: 950px;
  }
}

/* -------for tablet view------- */
@media only screen and (max-width: 980px) and (min-width: 601px) {
  .wrapper {
    width: 96% !important;
    margin: 0 auto;
  }

  .bottomContent {
    padding: 10px 2px;
    width: 48%;
    margin-bottom: 2%;
  }

  .name_designation h1 {
    color: #4C4C4C;
    font-family: ITC;
    font-size: 18.906px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.23px;
  }
  
  .name_designation p {
    color: #4C4C4C;
    font-family: ITC;
    font-size: 15.25px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
}

/* for mobile view------------ */
@media only screen and (max-width: 600px) and (min-width: 100px) {
  .wrapper {
    width: 96% !important;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4%;
  }

  .bottomContent {
    display: flex;
    width: 94%;
    height: 120px;
  }

  .logo {
    height: 80px;
    width: 80px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding-left: 0px !important;
  }

  .logo img {
    width: 70%;
    border-radius: 50%;
  }

  .name_designation {
    /* border: 2px solid green; */
    padding-left: 2%;
    width: 100%;
  }

  .name_designation h1 {
    color: #4C4C4C;
    font-family: ITC;
    font-size: 16.906px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.23px;
    margin: 0;
    text-align: justify;
  }
  
  .name_designation p {
    color: #4C4C4C;
    font-family: ITC;
    font-size: 15.25px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
}
